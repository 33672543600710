import {MonoTypeOperatorFunction} from 'rxjs';
import {distinctUntilChanged, publishReplay, refCount, share, startWith} from 'rxjs/operators';


/** Потоки состояний должны всегда иметь состояние (startWith),
 * эммитить состояние только если оно изменилось, при подписки отдавать последние состояние (replay),
 * шарть состояние (не создавать новый поток для каждого, кто подписался) */


export const comparator = (a, b) => {
  if (typeof a !== typeof b) { return false; }
  if (a === undefined || b === undefined) { return true; }
  if (a === null && b === null) { return true; }
  if (a === null && b !== null) { return false; }
  if (a !== null && b === null) { return false; }
  if (['string', 'number', 'boolean'].includes(typeof a)) {
    return a === b;
  }
  return JSON.stringify(a) === JSON.stringify(b);
};


/** шарим поток состояний */
export function shareState<T>(_default: T): MonoTypeOperatorFunction<T> {
  return input$ => input$.pipe(
    startWith(_default),
    distinctUntilChanged(comparator),
    publishReplay(1),
    refCount<T>()
  );
}

/** Исользуется в потоках где есть только map и этот поток зависит только от других ShareState */
export function shareStateReplay<T>(): MonoTypeOperatorFunction<T> {

  return input$ => input$.pipe(
    distinctUntilChanged(comparator),
    publishReplay(1),
    refCount<T>()
  );
}


/** шарим поток событий */
export function shareEvent<T>(): MonoTypeOperatorFunction<T> {
  return share<T>();
}

/**  */
// export class StateSubject<T> extends BehaviorSubject<T> {
//
//   constructor(private _value: T) {
//     super(_value);
//     this._value = _value;
//   }
//
// }
