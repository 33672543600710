import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {LoopBackConfig} from '../../lb.config';
import {Observable} from 'rxjs';
import {UserStoryContent, UserStoryContentInterface, UserStoryContentType, UserStoryPlaybackStats} from '../../models';
import {map} from 'rxjs/operators';

/**
 * Api services for the `UserStory` model.
 */
@Injectable()
export class UserStoryApi extends BaseLoopBackApi {

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(SDKModels) protected models: SDKModels,
        @Inject(LoopBackAuth) protected auth: LoopBackAuth,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
        @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
    ) {
        super(http, models, auth, searchParams, errorHandler);
    }

    /**
     * Контент для стори. Файлы в модели StoryFileContainer, тип модели - это имя контейнера
     * @param id
     * @param type
     */
    public getStoryContent(id: number, type: UserStoryContentType): Observable<UserStoryContent> {
        const url = [
            LoopBackConfig.getPath(),
            LoopBackConfig.getApiVersion(),
            this.model.getModelDefinition().plural,
            'web/content/:type/:id'
        ].join('/');
        return this.request('GET', url, {type, id}, undefined)
            .pipe(
                map<UserStoryContentInterface, UserStoryContent>(content => new UserStoryContent(content))
            );
    }

    /**
     * Юзер не понял стори и приступит к упражнениям
     * @param id
     * @param timezoneOffset
     * @return {Observable<any>}
     */
    public sendStoryResultStudy(id: number, timezoneOffset: number): Observable<void> {
        const url = [
            LoopBackConfig.getPath(),
            LoopBackConfig.getApiVersion(),
            this.model.getModelDefinition().plural,
            'web/study/:id'
        ].join('/');
        return this.request('POST', url, {id}, {timezoneOffset});
    }

    /**
     * Юзер понял стори
     * @param id
     * @param timezoneOffset
     * @return {Observable<any>}
     */
    public sendStoryResultUnderstand(id: number, timezoneOffset: number): Observable<void> {
        const url = [
            LoopBackConfig.getPath(),
            LoopBackConfig.getApiVersion(),
            this.model.getModelDefinition().plural,
            'web/understand/:id'
        ].join('/');
        return this.request('POST', url, {id}, {timezoneOffset});
    }

    /**
     * Добавление статистики по прослушиванию стори
     * @param id
     * @param stats
     * @param timezoneOffset
     * @return {Observable<any>}
     */
    public addPlaybackStats(id: number, stats: UserStoryPlaybackStats, timezoneOffset: number): Observable<void> {
        const url = [
            LoopBackConfig.getPath(),
            LoopBackConfig.getApiVersion(),
            this.model.getModelDefinition().plural,
            'web/add-playback-stats/:id'
        ].join('/');
        return this.request('POST', url, {id}, {timezoneOffset}, {stats});
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `UserStory`.
     */
    public getModelName() {
        return 'UserStory';
    }
}
