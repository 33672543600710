/* tslint:disable */
import {PictureGroup, PictureGroupInterface} from './PictureGroup';

declare var Object: any;

export type PaymentPlatform = 'android' | 'ios' | 'paypal';

export interface UserGroupPurchaseInterface {
  id?: number;
  groupId?: string;
  userAccountId?: string;
  orderId?: string;
  purchaseTime?: Date;
  platform?: PaymentPlatform;
  created_at?: Date;
  pictureGroup?: PictureGroupInterface;
}

export class UserGroupPurchase implements UserGroupPurchaseInterface {
  id: number;
  groupId: string;
  userAccountId: string;
  orderId: string;
  purchaseTime: Date;
  platform: PaymentPlatform;
  created_at: Date;
  pictureGroup: PictureGroup;

  constructor(data: UserGroupPurchaseInterface = {}) {
    Object.assign(this, data, {
      pictureGroup: data.pictureGroup && PictureGroup.factory(data.pictureGroup),
    });
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UserGroupPurchase`.
   */
  public static getModelName() {
    return 'UserGroupPurchase';
  }

  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of UserGroupPurchase for dynamic purposes.
   **/
  public static factory(data: UserGroupPurchaseInterface): UserGroupPurchase {
    return new UserGroupPurchase(data);
  }

  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'UserGroupPurchase',
      plural: 'UserGroupPurchases',
      properties: {
        id: {
          name: 'id',
          type: 'string'
        },
        groupId: {
          name: 'groupId',
          type: 'string'
        },
        userAccountId: {
          name: 'userAccountId',
          type: 'string'
        },
        orderId: {
          name: 'orderId',
          type: 'string'
        },
        purchaseTime: {
          name: 'purchaseTime',
          type: 'date'
        },
        platform: {
          name: 'platform',
          type: 'string'
        },
        created_at: {
          name: 'created_at',
          type: 'date'
        },
      },
      relations: {
        pictureGroup: {
          name: 'pictureGroup',
          type: 'PictureGroup',
          model: 'PictureGroup'
        }
      }
    };
  }
}
