export const environment = {
  production: true,
  apiVersion: 'api',
  baseURL: 'https://api.engym.com',
  authURL: {
    google: '/auth/google',
    facebook: '/auth/facebook',
  },
  learning: {
    inactiveTimeout: 1000 * 60 * 10, // 10 минут
    resultWindowDelay: 1000,
    guestMode: {
      reminderRate: 15
    },
    audio: {
      commonVolume: 0.5,
      slowPlaybackRate: 0.65,
      wordsVolume: 0.75,
      storyPlayerVolume: 0.5,
      notificationsVolume: 0.7,
      analyzer: {
        volumeThreshold: 0.003,
      }
    },
    pick: {
      gridSize: 6,
      audioListSize: 3
    },
    pronounce: {
      startPaused: true,
      onRecognizeAnimationTime: 500
    }
  },
  paypal: {
    clientID: 'Af4pmi_U7rxGfyg2eipwQcHiZECzQHNx2gLLFy07OHP7P4QJtkPaSZkd_ka0_QYwEqQrcoqIXmXHC4Bf',
    mode: 'sandbox'
  }
};
