/* tslint:disable */
import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {nsUserStatistics, UserStatistics, UserStatisticsInterface} from '../../models';
import {LoopBackConfig} from '../../lb.config';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';

/**
 * Api services for the `UserStatistics` model.
 */
@Injectable()
export class UserStatisticsApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, searchParams, errorHandler);
  }

  /**
   * @method getStats
   * @license MIT
   * @param {nsUserStatistics.StatsType} type
   * @return {Observable<UserStatistics>}
   */
  public getStats(type: nsUserStatistics.StatsType): Observable<UserStatistics> {
    const timezoneOffset = (new Date).getTimezoneOffset();
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      ':type'
    ].join('/'), {type}, {timezoneOffset})
      .pipe(
        map((data: UserStatisticsInterface) => this.model.factory(data))
      );
  }

  /**
   * @method getTotalStudy
   * @license MIT
   * @return {Observable<nsUserStatistics.Study>}
   */
  public getTotalStudy(): Observable<nsUserStatistics.Study> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'study'
    ].join('/'));
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UserStatistics`.
   */
  public getModelName() {
    return 'UserStatistics';
  }
}
