/* tslint:disable */
import {Word, WordInterface} from './Word';

declare var Object: any;

export interface PicturePoolHistoryInterface {
  wordId?: string;
  shownAt?: Date;
  scheduledAt?: Date;
  repetition?: number;
  skippedRepetitions?: number;
  thinkingTime?: number;
  totalTime?: number;
  recognized?: boolean;
  attempts?: number;
  dontShowAgain?: boolean;
  method?: number;
  word?: WordInterface;
}

export class PicturePoolHistory implements PicturePoolHistoryInterface {
  wordId: string;
  shownAt: Date;
  scheduledAt: Date;
  repetition: number;
  skippedRepetitions: number;
  thinkingTime: number;
  totalTime: number;
  recognized: boolean;
  attempts: number;
  dontShowAgain: boolean;
  method: number;
  word: Word;

  constructor(data: PicturePoolHistoryInterface = {}) {
    Object.assign(this, data, {
      word: data.word && Word.factory(data.word)
    });
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PicturePoolHistory`.
   */
  public static getModelName() {
    return "PicturePoolHistory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PicturePoolHistory for dynamic purposes.
  **/
  public static factory(data: PicturePoolHistoryInterface): PicturePoolHistory{
    return new PicturePoolHistory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PicturePoolHistory',
      plural: 'PicturePoolHistory',
      properties: {
        "wordId": {
          "type": "string",
          "required": true
        },
        "shownAt": {
          "type": "date",
          "description": "Дата показа юзеру, присылается клиентом",
          "required": true
        },
        "scheduledAt": {
          "type": "date",
          "description": "Дата запланированного показа слова",
          "required": true
        },
        "repetition": {
          "type": "number",
          "description": "Какое это по счету повторение слова",
          "required": true
        },
        "skippedRepetitions": {
          "type": "number",
          "description": "Сколько повторений было пропущено по причине показа слова юзеру позже нужного срока",
          "required": true
        },
        "thinkingTime": {
          "type": "number",
          "description": "Время, потраченное на слово",
          "required": true
        },
        "recognized": {
          "type": "boolean",
          "description": "Назвал ли юзер слово",
          "required": true
        },
        "attempts": {
          "type": "number",
          "description": "Кол-во попыток",
          "required": true
        },
        "dontShowAgain": {
          "type": "boolean",
          "description": "Юзер отказался от изучения этого слова",
          "required": true
        },
        "method": {
          "type": "number",
          "required": true,
          "description": "1 - посмотреть на картинку и назвать слово; 2 - прослушать слово и выбрать картинку; 3 - посмотреть на картинку и оценить себя вручную свайпом влево/вправо, без микрофона."
        }
      },
      relations: {
        word: {
          name: 'word',
          type: 'any',
          model: ''
        },
        user: {
          name: 'user',
          type: 'any',
          model: ''
        }
      }
    }
  }
}
