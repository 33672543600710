/* tslint:disable */
declare var Object: any;

export interface StorageFileInterface {
  container?: string;
  field?: string;
  name?: string;
  originalFilename?: string;
  size?: number;
  type?: string;
}

export class StorageFile implements StorageFileInterface {
  container: string;
  field: string;
  name: string;
  originalFilename: string;
  size: number;
  type: string;

  public static parseResponse(response: string): StorageFile {
    try {
      const parsed = JSON.parse(response);
      return new StorageFile(parsed.result.files.file[0]);
    } catch (e) {
    }
  }

  constructor(data: StorageFileInterface = {}) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StorageFile`.
   */
  public static getModelName() {
    return "StorageFile";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of StorageFile for dynamic purposes.
  **/
  public static factory(data: StorageFileInterface): StorageFile{
    return new StorageFile(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'StorageFile',
      plural: 'StorageFiles',
      properties: {
        'container': {
          name: 'container',
          type: 'string'
        },
        'field': {
          name: 'field',
          type: 'string'
        },
        'name': {
          name: 'name',
          type: 'string'
        },
        'originalFilename': {
          name: 'originalFilename',
          type: 'string'
        },
        'size': {
          name: 'size',
          type: 'number'
        },
        'type': {
          name: 'type',
          type: 'string'
        }
      },
      relations: {
      }
    }
  }
}
