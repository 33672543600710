/* tslint:disable */
import {Injectable} from '@angular/core';
import {
  PicturePoolHistory,
  PicturePoolWord,
  FileContainer,
  UserAccount,
  Word,
  Picture,
  PictureGroup,
  PicturePool,
  WordPronunciation,
  WordUseExample,
  UserStatistics,
  PicturePoolItem,
  UserGroupPurchase,
  PaypalPayment,
  UserReport,
  StorageFile,
  UserFeedback,
  UserSubscription,
  UserLevel,
  StoryFileContainer,
  UserStory, UserExercise
} from '../../models';
import {Statistic} from '../../models/Statistic';

export interface Models {
  [name: string]: any
}

@Injectable()
export class SDKModels {

  private models: Models = {
    FileContainer: FileContainer,
    StoryFileContainer: StoryFileContainer,
    StorageFile: StorageFile,
    UserAccount: UserAccount,
    UserStory: UserStory,
    Word: Word,
    Picture: Picture,
    WordPronunciation: WordPronunciation,
    WordUseExample: WordUseExample,
    UserStatistics: UserStatistics,
    UserSubscription: UserSubscription,
    PicturePool: PicturePool,
    PicturePoolItem: PicturePoolItem,
    PicturePoolWord: PicturePoolWord,
    PicturePoolHistory: PicturePoolHistory,
    PictureGroup: PictureGroup,
    PaypalPayment: PaypalPayment,
    UserGroupPurchase: UserGroupPurchase,
    UserReport: UserReport,
    UserFeedback: UserFeedback,
    UserLevel: UserLevel,
    UserExercise: UserExercise,
    Statistic: Statistic
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
