import {Picture, PictureInterface} from './Picture';

declare var Object: any;

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface PayloadRandomPicture {
  filename: string;
  rect: Rect;
}

export interface PayloadRandomAudio {
  filename: string;
}

export interface Payload {
  random_pictures?: PayloadRandomPicture[];
  random_audio?: PayloadRandomAudio[];
}

export interface UserExerciseInterface {
  id: number; // (ID упражнения юзера),
  story_id: number; // (ссылка на стори),
  state: string; // (not_seen; passed; failed),
  learn_object_type: string; // (word_picture),
  exercise_type: string; // (pick_picture_for_word; pick_pronounce_for_word),
  order: number; // (порядок внутри стори),
  group: number; // (число для группировки упражнений, заложил на будущее),
  learn_object: {
    id: number;
    word_id: string;
    // Прежний объект Picture, ниже примеры заполненных полей
    picture: PictureInterface;
  };
  // Доп. данные для упражнений.
  // Для word_picture здесь будут 5 случайных картинок для построения сетки сетки
  payload: Payload;
}

export class UserExercise implements UserExerciseInterface {

  public id: number;
  public story_id: number;
  public state: string;
  public learn_object_type: string;
  public exercise_type: string;
  public order: number;
  public group: number;
  public learn_object: {
    id: number;
    word_id: string;
    picture: Picture;
  };
  public payload: Payload;
  public failedOrder: number;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UserExercise`.
   */
  public static getModelName() {
    return 'UserExercise';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of UserExercise for dynamic purposes.
   **/
  public static factory(data): UserExercise{
    return new UserExercise(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'UserExercise',
      plural: 'UserExercises',
      properties: {},
      relations: {}
    };
  }
}
