import {BrowserModule} from '@angular/platform-browser';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {NgModule, Provider} from '@angular/core';
// import * as Raven from 'raven-js';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from './auth-guard.service';
import {SDKBrowserModule, StoryFileContainerApi, UserStoryApi} from './shared/sdk';
import {NotfoundPageComponent} from './static-pages/notfound-page/notfound-page.component';
import {SupportComponent} from './support/support.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from './shared/shared.module';
import {TitleResolver} from './title.resolver';
import {LevelsService} from './shared/services/levels.service';
import {UserLevelApi} from './shared/sdk/services/custom/UserLevel';
import {HasLevelsGuard} from './shared/services/has-levels.guard';
import {AudioPlayerService} from './learning-process/services/audio-player.service';
import {StudyStoryDetailedService} from './shared/components/study-story-detailed/study-story-detailed.service';
import { GuestLoginComponent } from './guest-login/guest-login.component';
import { GuestLoginFormComponent } from './guest-login/guest-login-form/guest-login-form.component';
import {GuestLoginService} from './guest-login/guest-login.service';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import {ConfirmEmailService} from './confirm-email/confirm-email.service';

// export class RavenErrorHandler implements ErrorHandler {
//   public handleError(err: any): void {
//     console.log(err);
//     Raven.captureException(err);
//   }
// }

const providers: Provider[] = [
  { provide: LocationStrategy, useClass: HashLocationStrategy },
  AuthGuard,
  TitleResolver,
  AuthGuard,
  LevelsService,
  UserLevelApi,
  HasLevelsGuard,
  StoryFileContainerApi,
  UserStoryApi,
  AudioPlayerService,
  StudyStoryDetailedService,
  GuestLoginService,
  ConfirmEmailService
];

// if (environment.production) {
//   Raven
//     .config('https://3cfd9a8e89714b88b851936b317debb5@sentry.io/1261147')
//     .install();
//   providers.push(
//     { provide: ErrorHandler, useClass: RavenErrorHandler },
//   );
// }

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    SDKBrowserModule.forRoot()
  ],
  declarations: [
    AppComponent,
    SupportComponent,
    NotfoundPageComponent,
    GuestLoginComponent,
    GuestLoginFormComponent,
    ConfirmEmailComponent
  ],
  providers,
  bootstrap: [AppComponent]
})
export class AppModule {
}
