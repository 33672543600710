<div class="confirm-email-wrapper position-fixed w-100 h-100 d-flex justify-content-center align-items-center">
    <div class="confirm-email-window d-flex flex-column justify-content-center justify-content-md-start align-items-center bg-light p-4 position-relative overflow-hidden">
        <span class="position-absolute close font-weight-light" (click)="confirmEmailService.closeConfirmEmail()">&times;</span>
        <h3 class="text-darkblue mb-0 mt-3">Confirm E-mail</h3>
        <img src="assets/confirm-email.png" class="my-3" alt="">
        <p class="mb-2 text-center">
            A verification link has been sent to your email address.<br>
            Please, verify your email to gain full access to the application.
        </p>
        <a *ngIf="resendLetter$ | async; else tplResendLetter" class="mb-5 text-info">The verification link has been sent</a>

        <div *ngIf="resendLetterLoading$ | async"
             class="loader-wrap position-absolute d-flex justify-content-center align-items-center">
            <img src="assets/loading.gif" alt="">
        </div>
    </div>
</div>

<ng-template #tplResendLetter>
    <a class="mb-5 send-letter text-info" (click)="resendLetter.next()">Press here
        if you haven’t received the letter</a>
</ng-template>

