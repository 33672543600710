/* tslint:disable */
import {Word, WordInterface} from './Word';
import {UserAccount, UserAccountInterface} from './UserAccount';

declare var Object: any;

export interface PicturePoolWordInterface {
  wordId?: string;
  userAccountId?: string;
  learned?: boolean;
  repetition?: number;
  timesShown?: number;
  dontShowAgain?: boolean;
  progressResetCounter?: number;
  needToRebuild?: boolean;
  progressResetDate?: Date;
  word?: WordInterface;
  user?: UserAccountInterface;
}

export class PicturePoolWord implements PicturePoolWordInterface {
  wordId: string;
  userAccountId: string;
  learned: boolean;
  repetition: number;
  timesShown: number;
  dontShowAgain: boolean;
  progressResetCounter: number;
  needToRebuild: boolean;
  progressResetDate: Date;
  word: Word;
  user: UserAccount;

  constructor(data: PicturePoolWordInterface = {}) {
    Object.assign(this, data, {
      word: data.word && Word.factory(data.word),
      user: data.user && UserAccount.factory(data.user)
    });
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PicturePoolWord`.
   */
  public static getModelName() {
    return "PicturePoolWord";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PicturePoolWord for dynamic purposes.
  **/
  public static factory(data: PicturePoolWordInterface): PicturePoolWord{
    return new PicturePoolWord(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PicturePoolWord',
      plural: 'PicturePoolWords',
      properties: {
        "wordId": {
          "type": "string",
          "required": true,
          "id": true
        },
        "userAccountId": {
          "type": "string",
          "required": true,
          "id": true
        },
        "learned": {
          "type": "boolean",
          "required": true,
          "default": false,
          "description": "Выучено ли слово. Выученные слова в пуле больше не участвуют."
        },
        "repetition": {
          "type": "number",
          "required": true,
          "default": 0,
          "description": "Текущая стадия изучения"
        },
        "timesShown": {
          "type": "number",
          "required": true,
          "default": 0,
          "description": "Сколько раз слово было показано юзеру"
        },
        "dontShowAgain": {
          "type": "boolean",
          "required": true,
          "default": false,
          "description": "Юзер отказался от изучения этого слова"
        },
        "progressResetCounter": {
          "type": "number",
          "description": "Сколько раз был сброшен прогресс из-за ошибок юзера",
          "required": true,
          "default": 0
        },
        "needToRebuild": {
          "type": "boolean",
          "description": "Требуется ли перегенерировать элементы пула для этого слова",
          "required": true,
          "default": false
        },
        "progressResetDate": {
          "type": "date",
          "description": "Когда последний раз сбрасывался прогресс. Нужно для восстановления кривой"
        }
      },
      relations: {
        word: {
          name: 'word',
          type: 'any',
          model: ''
        },
        user: {
          name: 'user',
          type: 'any',
          model: ''
        }
      }
    }
  }
}
