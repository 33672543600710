/* tslint:disable */
import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Subject, throwError} from 'rxjs';
import {ServerError, ServerValidationError} from '../../models';

/**
 * Default error handler
 */
@Injectable()
export class ErrorHandler {

  private errorSource = new Subject<any>();
  public errorOccured$ = this.errorSource.asObservable();

  public handleError(response: HttpErrorResponse): any {
    const serverError = response.error.error || <ServerError>{
      statusCode: response.status,
      message: response.message
    };
    let err: Error;
    if (serverError.statusCode === 422) {
      err = new ServerValidationError(serverError.message, serverError.details);
    }
    else {
      err = new ServerError(serverError.message, serverError.statusCode, serverError.code);
    }
    this.errorSource.next(err);
    return throwError(err);
  }
}
