/* tslint:disable */

declare var Object: any;

export namespace nsSubscription {
  export interface User {
    "subscriptionId"?: number;
    "platform"?: string; // apple / google / paypal
    "userAccountId"?: string;
    "startTime"?: Date;
    "expiryTime"?: Date;
    "canceled"?: boolean;
    "orderId"?: string;
    "token"?: string;
    "created_at"?: Date;
    "id"?: number;
    "subscription"?: Plan;
  }
  export interface Plan {
    "name"?: string;
    "description"?: string;
    "defaultPriceUSD"?: string;
    "sku"?: string;
    "version"?: number;
    "active"?: boolean;
    "period"?: string; // P1M / P3M/ P6M / P1Y
    "changed_by"?: string;
    "changed_at"?: Date;
    "id"?: number;
    "created_by"?: string;
  }
}


export enum SkuType {
  s_p1m = 's_p1m',
  s_p3m = 's_p3m',
  s_p6m = 's_p6m',
  s_p1y = 's_p1y'
}

export interface UserSubscriptionInterface {
  userSubscription?: nsSubscription.User
  actualSubscriptions?: nsSubscription.Plan[]
}

export class UserSubscription implements UserSubscriptionInterface {
  userSubscription: nsSubscription.User;
  actualSubscriptions: nsSubscription.Plan[];

  get activeSubscription(): nsSubscription.Plan {
    if (this.userSubscription && !this.userSubscription.canceled) {
      return this.userSubscription.subscription
    }
    return null;
  }

  constructor(data: UserSubscriptionInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UserSubscription`.
   */
  public static getModelName() {
    return "UserSubscription";
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of UserSubscription for dynamic purposes.
   **/
  public static factory(data: UserSubscriptionInterface): UserSubscription{
    return new UserSubscription(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'UserSubscription',
      plural: 'UserSubscriptions',
      properties: {},
      relations: {}
    }
  }
}
