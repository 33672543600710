import {Injectable, OnDestroy} from '@angular/core';
import {UserLevelApi} from '../sdk/services/custom/UserLevel';
import {EMPTY, merge, Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {
  catchError,
  filter,
  flatMap,
  map,
  mapTo,
  publishReplay,
  refCount,
  startWith,
  tap, withLatestFrom
} from 'rxjs/operators';
import {LearnPage, UserLevel, UserStory} from '../sdk/models';
import {shareState, shareStateReplay} from '../rx.operators';
import {LoggerService} from '../sdk/services/custom';

/**
 * Сервис для одной загрузки левелов
 */
@Injectable()
export class LevelsService implements OnDestroy {
  /** левелы с сервера с кэшированим для большей отзывчивости */
    // private levelsFromStorage = localStorage.getItem('levels$');
  public readonly levels = new ReplaySubject<void>(1);
  public readonly levels$: Observable<LearnPage> = this.levels.asObservable().pipe(
    flatMap(() => this.api.getLevelsList().pipe(
      catchError(err => EMPTY)
    )),
    // tap(levels => localStorage.setItem('levels$', JSON.stringify(levels))),
    // shareState((this.levelsFromStorage) ? JSON.parse(this.levelsFromStorage) : null)
    tap(levels => this.logger.info(this, 'LevelsList', levels)),
    publishReplay(1),
    refCount()
  );

  /** берем лист левалов из levelsService */
  public readonly levelsList$: Observable<UserLevel[]> = this.levels$.pipe(
    map(learnPage => {
      if (!learnPage) {
        return [];
      }
      const levels: UserLevel[] = [];
      for (const level of learnPage.levels) {
        levels.push(new UserLevel(level));
      }
      return levels;
    }),
    publishReplay(1),
    refCount()
  );

  public readonly hasLevels$: Observable<boolean> = this.levels$.pipe(
    startWith(false),
    map(levels => !!levels),
    publishReplay(1),
    refCount()
  );

  /** список доступных стори */
  public readonly stories$: Observable<UserStory[]> = this.levels$.pipe(
    map(levels => {
      if (!levels) {
        return [];
      }
      const stories: UserStory[] = [];
      for (const level of levels.levels) {
        for (const story of level.stories) {
          stories.push(story);
        }
      }
      return stories;
    }),
    tap(stories => this.logger.info(this, 'storiesList', stories)),
    publishReplay(1),
    refCount()
  );

  /** загрузка левалов */
  public readonly levelsLoading$ = merge(
    this.levels.asObservable().pipe(mapTo(true)),
    this.levelsList$.pipe(filter(item => !!item), mapTo(false))
  ).pipe(
    shareState(false)
  );

  /** есть ли стори для повторения в листе левалов  */
  public readonly hasRepetition$: Observable<boolean> = this.levels$.pipe(
    map(learnPage => learnPage?.repetitions?.length > 0),
    shareStateReplay()
  );

  /** число стори для повторения */
  public readonly repetitionCount$: Observable<number> = this.levels$.pipe(
    map(learnPage => learnPage?.repetitions?.length || 0),
    shareState(0)
  );

  private subscription: Subscription;
  private readonly any$ = merge(
    this.levelsLoading$,
    this.levelsList$,
    this.levels$,
    this.hasLevels$,
    this.stories$
  );

  constructor(
    private api: UserLevelApi,
    private logger: LoggerService
  ) {
    this.subscription = this.any$.subscribe();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
