/* tslint:disable */
import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {LoopBackConfig} from '../../lb.config';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {StoryFileContainer} from '../../models';

/**
 * Api services for the `StoryFileContainer` model.
 */
@Injectable()
export class StoryFileContainerApi extends BaseLoopBackApi {

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(SDKModels) protected models: SDKModels,
        @Inject(LoopBackAuth) protected auth: LoopBackAuth,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
        @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
    ) {
        super(http, models, auth, searchParams, errorHandler);
    }

    /**
     * Формирование URL к файлу.
     *
     * @param {string} container
     * @param {string} file
     * @returns {string} URl
     */
    public getFileUrl(container: string, file: string): string {
        return `${LoopBackConfig.getPath()}/story_content/${container}/${file}`;
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `StoryFileContainer`.
     */
    public getModelName() {
        return 'StoryFileContainer';
    }
}
