declare var Object: any;

export enum StoryState {
    not_started = 'not_started',
    in_study = 'in_study',
    in_repetition = 'in_repetition',
    finished = 'finished'
}

export enum UserStoryContentType {
    audio_ad = 'audio_ad'
}

export interface UserStoryPlaybackStats {
    time?: number;
    playbackEnded?: boolean;
}

export interface TimeSync {
    id: number;
    start: number;
    stop: number;
}

export interface TextFragment {
    id: number;
    text: string;
}

export interface UserStoryInterface {
    id?: number;
    unlocked?: boolean;
    play_count?: number;
    state?: StoryState;
    back_to_study?: boolean;
    chargeable?: boolean;
    repetition?: number;
    schedule?: string;
    allowed_start?: string;
    progress?: number;
    max_progress?: number;
    order?: number;
    name?: string;
    description?: string;
    content_id?: number;
    content_type?: UserStoryContentType;
    content?: UserStoryContentInterface;
}

export interface UserStoryDetailedInterface {
    id?: number;
    filename?: string;
    duration_ms?: number;
    sync?: TimeSync[];
    text_fragments?: TextFragment[];
}

export class UserStoryDetailed implements UserStoryDetailedInterface {
    public id: number;
    public filename: string;
    public duration_ms: number;
    public sync: TimeSync[];
    public text_fragments: TextFragment[];

    constructor(data = {}) {
        Object.assign(this, data);
    }
}

export interface UserStoryContentInterface {
    id?: number;
    text?: string;
    filename?: string;
    duration_ms?: number;
    detailed?: UserStoryDetailed;
    en_comment?: UserStoryDetailed;
    native_comment?: UserStoryDetailed;
    text_fragments?: TextFragment[];
    sync?: TimeSync[];
}

export class UserStoryContent implements UserStoryContentInterface {
    public duration_ms: number;
    public filename: string;
    public id: number;
    public text: string;
    public detailed: UserStoryDetailed;
    public en_comment: UserStoryDetailed;
    public native_comment: UserStoryDetailed;
    public text_fragments: TextFragment[];
    public sync: TimeSync[];

    constructor(data = {}) {
        Object.assign(this, data);
    }
}

export class UserStory implements UserStoryInterface {
    /**
     * ID стори юзера
     */
    public id: number;

    /**
     * Разблокировано ли для юзера
     */
    public unlocked: boolean;

    /**
     * Кол-во проигрываний
     */
    public play_count: number;

    /**
     * not_started, in_study, in_repetition, finished
     */
    public state: StoryState;

    /**
     * Возвращен в обучение
     */
    public back_to_study: boolean;

    /**
     * Требуется ли оплата
     */
    public chargeable: boolean;

    /**
     * Текущий номер повторения
     */
    public repetition: number;

    /**
     * Дата следующего повторения; NULL, если state!=in_repetition
     */
    public schedule: string;

    /**
     * Дата, с которой система помещает стори в список повторений; NULL, если state!=in_repetition
     */
    public allowed_start: string;

    /**
     * Дата следующего повторения; NULL, если state!=in_repetition
     */
    public schedule_date: Date;

    /**
     * Дата, с которой система помещает стори в список повторений; NULL, если state!=in_repetition
     */
    public allowed_start_date: Date;

    /**
     * Прогресс
     */
    public progress: number;

    /**
     * Максимальный прогресс
     */
    public max_progress: number;

    /**
     * Порядок внутри уровня
     */
    public order: number;

    /**
     * Название
     */
    public name: string;

    /**
     * Описание
     */
    public description: string;

    /**
     * ID контента
     */
    public content_id: number;

    /**
     * Тип контента
     */
    public content_type: UserStoryContentType;

    /**
     * контент хранится в модели
     */
    public content: UserStoryContent;

    public get isTimeForRepeat(): boolean {
        return this.state === StoryState.in_repetition
          && this.allowed_start_date
          && this.allowed_start_date < new Date();
    }

    public get isFinishedStory(): boolean {
        return this.state === StoryState.in_repetition || this.state === StoryState.finished;
    }

    constructor(data: UserStoryInterface = {}) {
        Object.assign(this, data);
        if (data.allowed_start) {
            this.allowed_start_date = new Date(data.allowed_start);
        }
        if (data.schedule) {
            this.schedule_date = new Date(data.schedule);
        }
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `UserStory`.
     */
    public static getModelName() {
        return 'UserStory';
    }

    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of UserStory for dynamic purposes.
     **/
    public static factory(data): UserStory {
        return new UserStory(data);
    }

    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    public static getModelDefinition() {
        return {
            name: 'UserStory',
            plural: 'UserStories'
        };
    }
}
