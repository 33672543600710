import {UserStory, UserStoryInterface} from './UserStory';
import {chain, sortBy} from 'lodash';

declare var Object: any;

export enum UserStateEnum {
    /**
     * Еще есть непройденные стори
     */
    in_progress = 'in_progress',

    /**
     * Остались только повторения
     */
    repetitions_only = 'repetitions_only',

    /**
     * Юзер прошел абсолютно всё
     */
    all_done = 'all_done'
}

export interface UserStateInterface {
    /**
     * ссылка на текущую стори юзера
     */
    user_story_id?: number;

    /**
     * ссылка на текущий уровень юзера
     */
    user_level_id?: number;

    /**
     * in_progress, repetitions_only, all_done
     */
    state?: UserStateEnum;
}

export interface UserLevelInterface {
    id?: number;
    unlocked?: boolean;
    level?: number;
    name?: string;
    description?: string;
    image_unlocked_filename?: string;
    image_locked_filename?: string;
    stories?: UserStoryInterface[];
}

export interface LearnPageInterface {
    user_state?: UserStateInterface;
    levels?: UserLevelInterface[];
}

export class LearnPage implements LearnPageInterface {
    /**
     * Состояние обучения юзера
     */
    public user_state: UserStateInterface;
    /**
     * Уровни со стори
     */
    public levels: UserLevel[];

    constructor(data: LearnPageInterface = {}) {
        Object.assign(this, data);
        if (Array.isArray(data.levels)) {
            this.levels = chain(data.levels)
                .map(level => new UserLevel(level))
                .sortBy('level')
                .forEach(level => level.stories = sortBy(level.stories, 'order'))
                .value();
        }
    }

    /**
     * Текущий уровень.
     */
    public get currentLevel(): UserLevel {
        return this.levels.find(level => level.id === this.user_state.user_level_id);
    }

    /**
     * Текущая изучаемая стори.
     */
    public get currentStory(): UserStory {
        return this.currentLevel
            .stories.find(story => story.id === this.user_state.user_story_id);
    }

    /**
     * Текущее состояние прохождения стори.
     */
    public get state(): UserStateEnum {
        return this.user_state.state;
    }

    /**
     * Отсортированная по schedule коллекция стори, которые необходимо повторить.
     */
    public get repetitions(): UserStory[] {
        const stories = this.levels
            .map<UserStory[]>(level => level.stories.filter(story => story.isTimeForRepeat))
            .reduce((acc, curr) => acc.concat(curr), []);
        return sortBy(stories, 'schedule_date');
    }

    public get finishedStoryList(): UserStory[] {
        return this.levels
            .map<UserStory[]>(level => level.stories.filter(story => story.isFinishedStory))
            .reduce((acc, curr) => acc.concat(curr), []);
    }
}

export class UserLevel {
    /**
     * ID уровня юзера
     */
    public id: number;

    /**
     * Разблокирован ли уровень для этого юзера
     */
    public unlocked: boolean;

    /**
     * Номер уровня, используется для упорядочивания
     */
    public level: number;

    /**
     * Наименование
     */
    public name: string;

    /**
     * Описание
     */
    public description: string;

    /**
     * Картинка для разблокированного уровня
     */
    public image_unlocked_filename: string;

    /**
     * Картинка для заблокированного уровня
     */
    public image_locked_filename: string;

    public stories: UserStory[];

    constructor(data: UserLevelInterface = {}) {
        Object.assign(this, data);
        if (Array.isArray(data.stories)) {
            this.stories = data.stories.map(story => new UserStory(story));
        }
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `UserLevel`.
     */
    public static getModelName() {
        return 'UserLevel';
    }

    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of UserLevel for dynamic purposes.
     **/
    public static factory(data): UserLevel {
        return new UserLevel(data);
    }

    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    public static getModelDefinition() {
        return {
            name: 'UserLevel',
            plural: 'UserLevels'
        };
    }
}
