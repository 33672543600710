import {Injectable, OnDestroy} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject, Subscription} from 'rxjs';
import {bufferCount} from 'rxjs/internal/operators';
import {environment} from '../../environments/environment';
import {LoggerService} from '../shared/sdk/services/custom';

/**
 * Сервис обслуживания гостевого доступа в приложении.
 * С его помощью можно {@link openModal открыть} модальник регистрации,
 * как прямым вызовом функции, так и {@link learning накопив} достаточное количество
 * пройденных слов.
 * @class GuestService
 */
@Injectable()
export class GuestLoginService implements OnDestroy {
  private learningBufferSub: Subscription;
  private learningBufferSource = new Subject<void>();

  private registerIsOpen = false;

  public get templateUserRegisterIsOpen(): boolean {
    return this.registerIsOpen;
  }

  constructor(
    private modalService: NgbModal,
    private logger: LoggerService
  ) {
    this.learningBufferSub = this.learningBufferSource
      .pipe(bufferCount(environment.learning.guestMode.reminderRate))
      .subscribe(() => this.showTemplateUserRegister());
  }

  public ngOnDestroy(): void {
    this.learningBufferSub.unsubscribe();
  }

  public showTemplateUserRegister(): void {
    this.registerIsOpen = true;
  }

  public hideTemplateUserRegister(): void {
    this.registerIsOpen = false;
  }

  /**
   * Спустя количество вызовов, определенное в `environment.learning.guestMode.reminderRate`
   * открывает модальник с напоминанием о регистрации.
   * Используем функцию во время упражнений, чтобы показывать пользователю окном регистрации
   * после некоторого количества пройденных слов.
   * @return {void}
   */
  public learning(): void {
    this.logger.info(this.constructor.name, 'Appending learning buffer');
    this.learningBufferSource.next();
  }
}
