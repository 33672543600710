/* tslint:disable */
import {Word, WordInterface} from './Word';
import {UserAccount, UserAccountInterface} from './UserAccount';

declare var Object: any;

export interface WordUseExampleInterface {
  id?: string;
  container?: string;
  wordId?: string;
  file_name?: string;
  word?: WordInterface,
  author?: string,
  userAccountId?: string,
  user?: UserAccountInterface
}

export class WordUseExample implements WordUseExampleInterface {
  id: string;
  container: string;
  wordId: string;
  file_name: string;
  word: Word;
  author: string;
  userAccountId: string;
  user: UserAccount;

  constructor(data: WordUseExampleInterface = {}) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WordUseExample`.
   */
  public static getModelName() {
    return "WordUseExample";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WordUseExample for dynamic purposes.
  **/
  public static factory(data: WordUseExampleInterface): WordUseExample{
    return new WordUseExample(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WordUseExample',
      plural: 'WordUseExamples',
      properties: {
        "id": {
          "name": "id",
          "type": "string",
          "id": true
        },
        "container": {
          "name": "container",
          "type": "string"
        },
        "file_name": {
          "name": "file_name",
          "type": "string"
        },
        "wordId": {
          "name": "wordId",
          "type": "string"
        },
        "author": {
          "name": "author",
          "type": "string"
        },
        "userAccountId": {
          "name": "userAccountId",
          "type": "string"
        }
      },
      relations: {
      }
    }
  }
}
