/* tslint:disable */
import {Picture, PictureInterface} from './Picture';

declare var Object: any;

export interface PictureGroupInterface {
  id? : string;
  name? : string;
  description? : string;
  active? : boolean;
  verified? : boolean;
  recommended? : boolean;
  price? : number;
  container? : string;
  file_name? : string;
  order? : number;
  rating? : number;
  is_default?: boolean;
  archived? : boolean;
  pictures?: PictureInterface[];
}

export class PictureGroup implements PictureGroupInterface {
  id: string;
  name: string;
  description: string;
  active: boolean;
  verified: boolean;
  recommended: boolean;
  price: number;
  container: string;
  file_name: string;
  order: number;
  rating: number;
  is_default: boolean;
  archived: boolean;
  pictures: Picture[];

  constructor(data: PictureGroupInterface = {}) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PictureGroup`.
   */
  public static getModelName() {
    return "PictureGroup";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PictureGroup for dynamic purposes.
  **/
  public static factory(data: PictureGroupInterface): PictureGroup{
    return new PictureGroup(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PictureGroup',
      plural: 'PictureGroups',
      properties: {
        "id": {
          name: "id",
          type: "string"
        },
        "name": {
          name: "name",
          type: "string"
        },
        "description": {
          name: "description",
          type: "string"
        },
        "active": {
          name: "active",
          type: "boolean",
          default: false
        },
        "verified": {
          name: "verified",
          type: "boolean",
          default: false
        },
        "is_default": {
          name: "is_default",
          type: "boolean",
          default: false
        },
        "recommended": {
          name: "recommended",
          type: "boolean",
          default: false
        },
        "price": {
          name: "price",
          type: "number",
          default: 0
        },
        "container": {
          name: "container",
          type: "string"
        },
        "file_name": {
          name: "file_name",
          type: "string"
        },
        "order": {
          name: "order",
          type: "number"
        },
        "rating": {
          name: "rating",
          type: "number"
        },
        "archived": {
          name: "archived",
          type: "boolean",
          default: false
        }
      },
      relations: {
        pictures: {
          name: 'pictures',
          type: 'Picture',
          model: 'Picture'
        }
      }
    }
  }
}
