/* tslint:disable */
import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {Observable} from 'rxjs';
import {LoopBackConfig} from '../../lb.config';
import {map, pluck, tap} from 'rxjs/internal/operators';
import {UserPictureGroup, UserPictureGroupInterface} from '../../models/UserPictureGroup';


/**
 * Api services for the `PictureGroup` model.
 *
 * **Details**
 *
 * Список групп для картинок
 */
@Injectable()
export class PictureGroupApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, searchParams, errorHandler);
  }

  /**
   * Возвращает список всех групп с отметками, выбраны и куплены ли они у данного пользователя.
   * @returns {Observable<UserPictureGroupInterface[]>} Информация о группах пользователя.
   */
  public getUserPictureGroups(): Observable<UserPictureGroup[]> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      'UserGroupSelecteds/userGroups'
    ].join('/'))
      .pipe(
        map((data: UserPictureGroupInterface[]) =>
          data.map(item => UserPictureGroup.factory(item))
        )
      );
  }

  /**
   * Обновляет выбранные группы текущего пользователя.
   * @param {string[]} ids Идентификаторы выбранных групп.
   * @returns {Observable<number>} Кол-во обновленных записей.
   */
  public updateUserPictureGroups(ids: string[]): Observable<number> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      'UserGroupSelecteds/userGroups'
    ].join('/'), undefined, undefined, {ids})
      .pipe(
        pluck('count')
      );
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `PictureGroup`.
   */
  public getModelName() {
    return 'PictureGroup';
  }
}
