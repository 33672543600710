/* tslint:disable */
import {UserAccount, UserAccountInterface} from './UserAccount';
import {WordUseExample, WordUseExampleInterface} from './WordUseExample';
import {WordPronunciation, WordPronunciationInterface} from './WordPronunciation';

declare var Object: any;

export interface WordInterface {
  id?: string;
  name?: string;
  tags?: string;
  created_at?: Date;
  created_by?: string;
  PoS?: string;
  freq?: number;
  spoken?: number;
  concrete?: boolean;
  ipa?: string;
  created?: UserAccountInterface;
  pronunciation?: WordPronunciationInterface[];
  examples?: WordUseExampleInterface[];
}

export class Word implements WordInterface {
  id: string;
  name: string;
  tags: string;
  created_at: Date;
  created_by: string;
  PoS: string;
  freq: number;
  spoken: number;
  concrete: boolean;
  ipa: string;
  created: UserAccount;
  pronunciation: WordPronunciation[];
  examples: WordUseExample[];

  public get audio(): WordPronunciation {
    return this.pronunciation[0];
  }

  public get example(): WordUseExample {
    return this.examples[0];
  }

  constructor(data: WordInterface = {}) {
    Object.assign(this, data, {
      created: data.created && UserAccount.factory(data.created),
      pronunciation: data.pronunciation && data.pronunciation.map(
        (item: WordPronunciationInterface) => WordPronunciation.factory(item)
      ),
      examples: data.examples && data.examples.map(
        (item: WordUseExampleInterface) => WordUseExample.factory(item)
      )
    });
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Word`.
   */
  public static getModelName() {
    return "Word";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Word for dynamic purposes.
  **/
  public static factory(data: WordInterface): Word{
    return new Word(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Word',
      plural: 'Words',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "tags": {
          name: 'tags',
          type: 'string'
        },
        "created_at": {
          name: 'created_at',
          type: 'Date'
        },
        "created_by": {
          name: 'created_by',
          type: 'string'
        },
        "PoS": {
          name: 'PoS',
          type: 'string'
        },
        "freq": {
          name: 'freq',
          type: 'number'
        },
        "spoken": {
          name: 'spoken',
          type: 'number'
        },
        "concrete": {
          name: 'concrete',
          type: 'boolean',
          default: true
        },
      },
      relations: {
        created: {
          name: 'created',
          type: 'any',
          model: ''
        },
        pronunciation: {
          name: 'pronunciation',
          type: 'any',
          model: ''
        },
        examples: {
          name: 'examples',
          type: 'any',
          model: ''
        }
      }
    }
  }
}
