/* tslint:disable */
import {PlatformInterface} from '../../../learning-process/platform.info';

export interface UserFeedbackInterface {
  text?: string;
  email?: string;
  platform?: PlatformInterface;
}

export class UserFeedback implements UserFeedbackInterface {
  email: string;
  text: string;
  platform: PlatformInterface;

  constructor(data: UserFeedbackInterface = {}) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserFeedback`.
   */
  public static getModelName() {
    return "UserFeedback";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserFeedback for dynamic purposes.
  **/
  public static factory(data: UserFeedbackInterface): UserFeedback{
    return new UserFeedback(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserFeedback',
      plural: 'UserFeedbacks',
      properties: {
        'text': {
          name: 'text',
          type: 'string'
        },
        'platform': {
          name: 'platform',
          type: 'any'
        }
      },
      relations: {
      }
    }
  }
}
