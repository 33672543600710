import { Component, OnInit } from '@angular/core';
import {ConfirmEmailService} from './confirm-email.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

  public readonly resendLetter$ = this.confirmEmailService.resendLetter$;
  public readonly resendLetter = this.confirmEmailService.resendLetter;
  public readonly resendLetterLoading$ = this.confirmEmailService.resendLetterLoading$;

  constructor(
    public confirmEmailService: ConfirmEmailService
  ) { }

  public ngOnInit() {
  }

}
