<ng-container *ngIf="!registerMode">
    <h3 class="text-darkblue text-center mx-2">Sign up to save you progress!</h3>
    <p class="text-info text-center mx-2">Sign up now to save all your progress and start doing more activities</p>
    <img src="assets/guest-login.png" class="my-3 my-md-5 guest-login-img" alt="guest-login">
    <button class="btn btn-info px-5 btn-sm" style="width: 250px" (click)="showRegisterMode()">Registration</button>
    <a class="text-secondary text-decoration-underline mt-4"
       style="cursor: pointer"
       (click)="guestLoginService.hideTemplateUserRegister()">To register later</a>
</ng-container>
<app-guest-login-form *ngIf="registerMode"></app-guest-login-form>
