/* tslint:disable */
import {Picture, PictureInterface} from './Picture';
import {Word} from './Word';

declare var Object: any;

export interface PicturePoolItemInterface {
  defaultWordId?: string;
  pictureId?: string;
  schedule?: Date;
  allowedStart?: Date;
  repetition?: number;
  size?: number;
  playAudio?: boolean;
  picture?: PictureInterface;
  changed_at?: Date;
  deleted?: boolean;
}

export class PicturePoolItem implements PicturePoolItemInterface {
  defaultWordId: string;
  pictureId: string;
  schedule: Date;
  allowedStart: Date;
  repetition: number;
  size: number;
  playAudio: boolean;
  picture: Picture;
  changed_at: Date;
  deleted: boolean;
  defaultWord: Word;

  constructor(data: PicturePoolItemInterface = {}) {
    Object.assign(this, data, {
      picture: data.picture && Picture.factory(data.picture)
    });
    this.defaultWord = this.picture.words.find(
      (word: Word) => word.id === data.defaultWordId
    )
  }

  public toJSON(): PicturePoolItemInterface {
    const copy = Object.assign({}, this, {
      picture: this.picture.toJSON()
    });
    delete copy.defaultWord;
    return copy;
  }

  public static factory(data: PicturePoolItemInterface): PicturePoolItem {
    return new PicturePoolItem(data);
  }
}