import {Directive, ElementRef, Input} from '@angular/core';
import nativeLangs, {NativeLangs} from '../native-langs';

/** отдает в селект лист языков ввиде option */
@Directive({
  selector: '[appNativeLangsControl]'
})
export class NativeLangsControlDirective {

  private readonly innerHTML: string;

  /** включаем ли пустое значение */
  // @Input() set includeDefault(value: boolean) {
  //   if (value) {
  //     this.innerHTML = '<option value="auto">Select your native language</option> ' + this.innerHTML;
  //   }
  //   this.el.nativeElement.innerHTML = this.innerHTML;
  // }

  constructor(private el: ElementRef) {
    const nativeLanguages: NativeLangs[] = nativeLangs.slice();
    this.innerHTML = nativeLanguages
      .map(nativeLanguage => `<option value="${nativeLanguage.lang}">${nativeLanguage.langName}</option>`)
      .join(' ');

    this.el.nativeElement.innerHTML = this.innerHTML;
  }

}
