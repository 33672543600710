/* tslint:disable */
import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {LoopBackConfig} from '../../lb.config';
import {Observable} from 'rxjs';
import {Statistic, SpendTimeStatistics, TypeEnum} from '../../models/Statistic';

/**
 * Api services for the `UserLevel` model.
 */
@Injectable()
export class StatisticApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, searchParams, errorHandler);
  }

  /**
   * @returns {Observable<Statistic>}
   */
  public getStatistic(timezoneOffset: number): Observable<Statistic> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'study'
    ].join('/'), {}, {timezoneOffset});
  }

  /**
   * @returns {Observable<SpendTimeStatistics>}
   */
  public getSpendTimeStatistics(type: TypeEnum, timezoneOffset: number): Observable<SpendTimeStatistics> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      ':type'
    ].join('/'), {type}, {timezoneOffset});
  }

  /**
   * ДЛЯ УЧЕТА ВРЕМЕНИ В СТОРИ ИСПОЛЬЗОВАТЬ UserStoryService#sendPlaybackStats
   * @returns {Observable<void>}
   */
  // public sendSpentTime(timeMs: number, timezoneOffset: number): Observable<void> {
  //   return this.request('POST', [
  //     LoopBackConfig.getPath(),
  //     LoopBackConfig.getApiVersion(),
  //     this.model.getModelDefinition().plural,
  //     'time/:timeMs'
  //   ].join('/'), {timeMs}, {timezoneOffset});
  // }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Statistic`.
   */
  public getModelName() {
    return 'Statistic';
  }
}
