import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NotfoundPageComponent} from './static-pages/notfound-page/notfound-page.component';
import {AuthGuard} from './auth-guard.service';
import {SupportComponent} from './support/support.component';
import {TitleResolver} from './title.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'learn-page',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    canActivateChild: [AuthGuard],
    data: {
      forAnonymous: false
    }
  },
  {
    path: 'user',
    loadChildren: () => import('./login-page/login-page.module').then(m => m.LoginPageModule),
  },
  {
    path: 'support',
    component: SupportComponent,
    resolve: {
      setTitle: TitleResolver
    },
    data: {
      title: 'Support'
    }
  },
  {
    path: 'learn',
    loadChildren: () => import('./learning-process/learning-process.module').then(m => m.LearningProcessModule),
    canActivateChild: [AuthGuard],
    data: {
      forAnonymous: false
    }
  },
  {
    path: 'repeat',
    loadChildren: () => import('./repeat/repeat.module').then(m => m.RepeatModule),
    canActivateChild: [AuthGuard],
    data: {
      forAnonymous: false
    }
  },
  {
    path: 'subscription',
    loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule),
  },
  {
    path: '404',
    component: NotfoundPageComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '404'
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
