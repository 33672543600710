import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';
import {ErrorHandler, LoopBackAuth, LoopBackConfig, UserAccountApi, ServerError} from './shared/sdk';
import {GuestLoginService} from './guest-login/guest-login.service';
import {ConfirmEmailService} from './confirm-email/confirm-email.service';

declare var navigator: Navigator;

const userAgent = navigator.userAgent || '';
const appVersion = navigator.appVersion || '';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: `
      <div class="container mt-4" *ngIf="isIE">
          <div class="bg-danger p-4 text-white">
              <h4>Your browser is not supported</h4>
              Upgrade to the latest version of Chrome, Firefox or Safari.
          </div>
      </div>
      <app-confirm-email *ngIf="confirmEmailService.confirmEmailIsOpen"></app-confirm-email>
      <app-guest-login *ngIf="guestLoginService.templateUserRegisterIsOpen"></app-guest-login>
      <router-outlet *ngIf="!isIE"></router-outlet>
  `
})
export class AppComponent {
  public isIE: boolean = userAgent.indexOf('MSIE') !== -1 || appVersion.indexOf('Trident/') > -1;

  constructor(private router: Router,
              private accountApi: UserAccountApi,
              private auth: LoopBackAuth,
              public confirmEmailService: ConfirmEmailService,
              public guestLoginService: GuestLoginService,
              private errorHandler: ErrorHandler) {
    LoopBackConfig.setBaseURL(environment.baseURL);
    LoopBackConfig.setApiVersion(environment.apiVersion);
    LoopBackConfig.setDebugMode(!environment.production);
    LoopBackConfig.filterOnUrl();

    errorHandler.errorOccured$
      .subscribe(
        (error: ServerError) => {
          if (this.accountApi.isAuthenticated() && error.statusCode === 401) {
            this.auth.clear();
          }
        }
      );

    auth.clear$
      .subscribe(() => router.navigateByUrl('/user/signin'));
  }
}
