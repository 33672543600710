import {AbstractControl, FormControl} from '@angular/forms';
import {formatNumber} from '@angular/common';

export const MS_IN_MINUTE = 60000;
export const MS_IN_HOUR = MS_IN_MINUTE * 60;
export const MONTHS_SHORT = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
export const WEEKDAYS_SHORT = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

export function randomArrayElement<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function daysInCurrentMonth() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
}

export function humanizeTime(ms: number): string {
  const hours = Math.floor(ms / MS_IN_HOUR);
  const minutes = Math.round(ms / MS_IN_MINUTE) % 60;
  let text: string = '';
  if (hours > 0) {
    text += hours.toString();
    text += 'h';
  }
  if (minutes > 0) {
    if (text) {
      text += ' ';
    }
    text += minutes.toString();
    text += 'm';
  }
  if (!text) {
    return '0m';
  }
  else {
    return text;
  }
}

/**
 * Потому что FormGroup#markAsDirty не работает.
 * @param {AbstractControl} control
 */
export function markAsDirty(control: AbstractControl): void {
  formGroupMarkAs('Dirty', control);
}

/**
 * Потому что FormGroup#markAsTouched не работает.
 * @param {AbstractControl} control
 */
export function markAsTouched(control: AbstractControl): void {
  formGroupMarkAs('Touched', control);
}

/**
 * Потому что FormGroup#markAsUntouched не работает.
 * @param {AbstractControl} control
 */
export function markAsUntouched(control: AbstractControl): void {
  formGroupMarkAs('Untouched', control);
}

/**
 * Потому что FormGroup#markAsPristine не работает.
 * @param {AbstractControl} control
 */
export function markAsPristine(control: AbstractControl): void {
  formGroupMarkAs('Pristine', control);
}

function formGroupMarkAs(state: 'Dirty'|'Touched'|'Untouched'|'Pristine',
                         control: AbstractControl): void {
  const methodName = `markAs${state}`;
  if (control.hasOwnProperty('controls')) {
    control[methodName]();
    const ctrl = <any>control;
    for (const inner in ctrl.controls) {
      if (ctrl.controls.hasOwnProperty(inner)) {
        formGroupMarkAs(state, ctrl.controls[inner] as AbstractControl);
      }
    }
  }
  else {
    (<FormControl>(control))[methodName]();
  }
}

