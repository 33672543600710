import {InjectionToken} from '@angular/core';

declare var window: Window;
declare var navigator: Navigator;

export interface PlatformInterface {
  name: string,
  language: string;
  appVersion: string;
  userAgent: string;
  innerWidth: number;
  innerHeight: number;
  devicePixelRatio: number;
  screen: Screen;
}

export const PLATFORM = new InjectionToken<PlatformInterface>('platform', {
  providedIn: 'root',
  factory: function() {
    return <PlatformInterface>{
      name: 'desktop',
      language: navigator.language,
      appVersion: navigator.appVersion,
      userAgent: navigator.userAgent,
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      devicePixelRatio: window.devicePixelRatio,
      screen: <Screen>{
        availWidth: window.screen.availWidth,
        availHeight: window.screen.availHeight,
        width: window.screen.width,
        height: window.screen.height
      }
    };
  }
});
