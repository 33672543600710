/* tslint:disable */

declare var Object: any;

export interface WeekdayStats {
  // Дата
  date: string;
  // Работал ли юзер с приложением столько, сколько нужно. NULL - день еще не закончился, либо информация за этот день отсутствует (например, в этот день юзер еще не зарегистрировался)
  active: boolean | null;
}

// Ответ с сервера
interface StatisticInterface {
  // Активность за последнюю неделю
  last_week_activity: WeekdayStats[];
  // Название текущего уровня
  level_name: string;
  // Иконка текущего уровня
  level_filename: string;
  // Сколько стори он изучил в рамках текущего уровня
  stories_learned: number;
  // Сколько всего стори на уровне
  stories_total: number;
}

// Период
export enum TypeEnum {
  today = 'today',
  total = 'total'
}
// Ответ с сервера
export interface SpendTimeStatistics {
  // Период
  type: TypeEnum;
  statistics: {
    // Кол-во изученных стори
    stories_listened: number;
    // Потраченное время в миллисекундах
    time: number;
  };
}

export class Statistic implements StatisticInterface {

  last_week_activity: WeekdayStats[];
  level_name: string;
  level_filename: string;
  stories_learned: number;
  stories_total: number;

  constructor(data = {}) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Statistic`.
   */
  public static getModelName() {
    return 'Statistic';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of UserLevel for dynamic purposes.
   **/
  public static factory(data): Statistic{
    return new Statistic(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Statistic',
      plural: 'Statistics',
      properties: {},
      relations: {}
    }
  }
}
