import {Injectable, OnDestroy} from '@angular/core';
import {Howl} from 'howler';
import {combineLatest, interval, merge, Observable, Subject, Subscription} from 'rxjs';
import {filter, map, publishReplay, refCount, scan, withLatestFrom} from 'rxjs/operators';
import {tap} from 'rxjs/internal/operators/tap';
import {shareEvent, shareState} from '../../shared/rx.operators';
import {LoggerService} from '../../shared/sdk/services/custom';
import {environment} from '../../../environments/environment';


export enum MediaControl {
  play = 'play',
  pause = 'pause',
  stop = 'stop',
}

export enum PlayerEvent {
  load = 'load',
  loadError = 'loaderror',
  playError = 'playerror',
  play = 'play',
  end = 'end',
  pause = 'pause',
  stop = 'stop'
}

export enum PlayerState {
  loading = 'loading',
  unloaded = 'unloaded',
  loaded = 'loaded'
}

export interface PlayerInfo {
  lastEvent?: PlayerEvent;
  state: PlayerState;
  duration: number;
  volume: number;
  isPlaying: boolean;
}

export interface AudioSource {
  src: string;
  autoplay?: boolean;
}

/** делаем Howl реактивным, так же здест контролируем что бы
 * в одно время не запускались разные аудио */
@Injectable()
export class AudioPlayerService implements OnDestroy {

  private howl: Howl;

  public volume: number;

  /** реактивные события от плеера */
  private readonly playerEvent = new Subject<PlayerEvent>();
  public readonly playerEvent$ = this.playerEvent.asObservable();

  /** заливаем путь до проигрываемой */
  public readonly audioUrl = new Subject<AudioSource>();
  private readonly audioUrl$ = this.audioUrl.asObservable()
    .pipe(
      shareEvent()
    );

  /** экземпляр Howl активный для удобства */
  private readonly howl$: Observable<Howl> = this.audioUrl$
    .pipe(
      // TODO понять что здесь происходит
      // throttleTime(100),
      map(audioSource => {
        const {src, autoplay} = audioSource;
        this.stopHowl(this.howl);
        this.getDefaultVolume();
        this.howl = new Howl({src, autoplay, html5: true, volume: this.volume });
        this.applyHowlEvents(this.howl);
        return this.howl;
      }),
      tap(howl => this.logger.info(this, 'howl$', howl)),
      publishReplay(1),
      refCount()
    );

  /** управление плеером - медиа контролы */
  public readonly control = new Subject<MediaControl>();
  private readonly control$ = this.control.asObservable().pipe(
    tap(control => this.logger.info(this, 'control$', control)),
    withLatestFrom(this.howl$),
    tap( ([control, howl]) => {
      switch (control) {
        case MediaControl.pause: { howl.pause(); break; }
        case MediaControl.play: { howl.play(); break; }
        case MediaControl.stop: { howl.stop(); break; }
        default: { break; }
      }
    }),
    shareEvent()
  );

  /** получить seek позицию */
  public readonly seek$: Observable<number> = merge(
    interval(30),
    this.control$
  ).pipe(
    withLatestFrom(this.howl$, (timer, howl) => howl),
    filter(howl => howl && howl.state() !== 'unloaded'),
    // Приходится запоминать последний seek, ибо при возвращении из паузы howl.seek()
    // по необъяснимым причинам выдает не число, а объект Howl.
    scan<Howl, number>(
      (lastSeek, howl) => {
        const currentSeek = howl.seek();
        return (typeof currentSeek === 'number') ? currentSeek : lastSeek;
      },
      0
    ),
    shareState(0)
  );

  /** информация по работе плеера */
  public readonly info$: Observable<PlayerInfo> = combineLatest(
    this.playerEvent$,
    this.howl$,
  ).pipe(
    map(([event, howl]) => {
      return {
        lastEvent: event,
        duration: howl.duration(),
        state: PlayerState[howl.state()],
        volume: howl.volume(),
        isPlaying: howl.playing(),
      };
    }),
    shareState({
      lastEvent: null,
      duration: 0,
      state: PlayerState.unloaded,
      volume: environment.learning.audio.commonVolume,
      isPlaying: false
    })
  );

  private anySubscription: Subscription;
  private readonly any$ = merge(
    this.control$,
    this.info$
  );

  constructor(private logger: LoggerService) {
    this.logger.info(this, 'Constructor');
    this.anySubscription = this.any$.subscribe();

    this.getDefaultVolume();
  }

  public getDefaultVolume() {
    const volume = parseFloat(localStorage.getItem('common:volume'));
    this.volume = isNaN(volume) ? environment.learning.audio.commonVolume : volume;
  }

  public ngOnDestroy(): void {
    this.logger.info(this, 'Destroy');
    this.cleanUp();
    this.anySubscription.unsubscribe();
  }

  /** убиваем старый экземпляр плеера если необходимо */
  private stopHowl(howl: Howl): void {
    if (howl) {
      howl.stop();
      // @ts-ignore
      howl.off();
      howl.unload();
    }
  }

  public cleanUp(): void {
    this.logger.info(this, 'cleanUp');
    this.stopHowl(this.howl);
  }

  /** применяет ивенты к howl */
  private applyHowlEvents(howl: Howl): void {
    howl.once('load', () => this.playerEvent.next(PlayerEvent.load));
    howl.once('loaderror', () => this.playerEvent.next(PlayerEvent.loadError));
    howl.on('playerror', () => this.playerEvent.next(PlayerEvent.playError));
    howl.on('play', () => this.playerEvent.next(PlayerEvent.play));
    howl.on('end', () => this.playerEvent.next(PlayerEvent.end));
    howl.on('pause', () => this.playerEvent.next(PlayerEvent.pause));
    howl.on('stop', () => this.playerEvent.next(PlayerEvent.stop));
  }

  public setSeek(position: number): void {
    if (this.howl) {
      // Firefox: если не пропустить значение через Math.floor, озвучка может заедать в конце.
      const duration = Math.floor(this.howl.duration());
      position = Math.max(0, Math.min(position, duration));
      this.howl.seek(position);
    }
  }

  public setVolume(volume: number): void {
    if (this.howl) {
      localStorage.setItem('common:volume', volume.toString(10));
      volume = Math.max(0, Math.min(volume, 1));
      this.volume = volume;
      this.howl.volume(volume);
    }
  }

}
