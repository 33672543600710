/* tslint:disable */
import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, pipe} from 'rxjs';
import {map, tap} from 'rxjs/internal/operators';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {LoopBackConfig} from '../../lb.config';
import {UserAccount, UserAccountInterface, AccessToken, UserSignupInterface, UserSettings} from '../../models';
import {LoggerService} from './logger.service';
import {AppError} from '../../../app-error.class';

/**
 * Api services for the `UserAccount` model.
 */
@Injectable()
export class UserAccountApi extends BaseLoopBackApi {
  private upToDate: boolean = false;

  private setUserPipe = pipe(
    map<UserAccountInterface, UserAccount>(data => this.model.factory(data)),
    tap<UserAccount>(user => {
      const token = user._token;
      delete user._token;
      this.auth.setToken({
        user,
        id: token,
        userId: user.id,
        created: new Date,
        ttl: 0,
        rememberMe: true
      });
      this.upToDate = true;
    })
  );

  private saveSettingsPipe = pipe(
    tap<UserSettings>(settings => {
      const user = this.auth.getCurrentUserData();
      Object.assign(user.settings, settings);
      this.auth.save();
    })
  );

  public get settings(): UserSettings {
    return this.auth.getCurrentUserData().settings;
  }

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler,
    @Optional() @Inject(LoggerService) protected logger: LoggerService
  ) {
    super(http, models, auth, searchParams, errorHandler);
  }

  /**
   * Вернет и запомнит текущего пользователя.
   * Нужно для авторизации через соц. сети - API редиректит на нас с одним лишь токеном.
   * @param {string} token
   * @returns {Observable<UserAccount>} Пользователь
   */
  public authorize(token: string = this.auth.getAccessTokenId()): Observable<UserAccount> {
    if (!token) {
      throw new AppError('Empty access token');
    }
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'me'
    ].join('/'), undefined, {access_token: token})
      .pipe(this.setUserPipe);
  }

  /**
   * Авторизация по логину и паролю.
   * @param {String} email email пользователя
   * @param {String} password пароль пользователя
   * @returns {Observable<UserAccount>} Пользователь с токеном
   */
  public login(email: string, password: string): Observable<UserAccount> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'login'
    ].join('/'), undefined, undefined, {email, password})
      .pipe(this.setUserPipe);
  }

  /**
   * Проверка доступности емейла для регистрации.
   * @param {string} email
   * @returns {Observable<boolean>}
   */
  public isEmailTaken(email: string): Observable<boolean> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'email-taken/:email'
    ].join('/'), {email})
      .pipe(
        map<{taken: boolean}, boolean>(data => data.taken)
      );
  }

  /**
   * Запрос на отправку емейл со ссылкой на восстановление пароля.
   * @param {string} email
   * @returns {Observable<void>}
   */
  public resetPassword(email: string): Observable<void> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'reset-password/:email'
    ].join('/'), {email});
  }

  /**
   * Обновление пароля при помощи кода восстановления.
   * @param {string} password
   * @param {string} token
   * @returns {Observable<UserAccount>}
   */
  public updatePassword(password: string, token: string): Observable<UserAccount> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'update-password/:token'
    ].join('/'), {token}, undefined, {data: {password}})
      .pipe(this.setUserPipe);
  }

  /**
   * Регистрация юзера.
   * @param {UserSignupInterface} data
   * @returns {Observable<UserAccount>} Пользователь с токеном
   */
  public register(data: UserSignupInterface): Observable<UserAccount> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'register'
    ].join('/'), undefined, undefined, data)
      .pipe(this.setUserPipe);
  }

  /**
   * @returns {Observable<UserSettings>}
   */
  public getSettings(): Observable<UserSettings> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'settings'
    ].join('/'))
      .pipe(this.saveSettingsPipe);
  }

  /**
   * @param {UserSettings} data
   * @returns {Observable<UserSettings>}
   */
  public updateSettings(data: UserSettings): Observable<UserSettings> {
    return this.request('PATCH', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'settings'
    ].join('/'), undefined, undefined, {settings: data})
      .pipe(this.saveSettingsPipe);
  }

  /**
   * Гостевой доступ.
   * Создает и возвращает временного пользователя с токеном.
   * @return {Observable<UserAccount>} Пользователь с токеном
   */
  public getTempUser(lang: string): Observable<UserAccount> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'create_temp_user'
    ].join('/'), undefined, {lang})
      .pipe(this.setUserPipe);
  }

  public resendLetter(): Observable<void> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'resend-verification-email'
    ].join('/'), undefined, undefined);
  }

  /**
   * Logout a user with access token.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public logout(): Observable<boolean> {
    this.auth.clear();
    return of(true);
    // const access_token = this.auth.getAccessTokenId();
    // return this.request('POST', [
    //   LoopBackConfig.getPath(),
    //   LoopBackConfig.getApiVersion(),
    //   this.model.getModelDefinition().plural,
    //   'logout'
    // ].join('/'), undefined, {access_token})
    //   .pipe(
    //     finalize(() => this.auth.clear())
    //   );
  }

  public isGuest(): boolean {
    const user = this.getCachedCurrent();
    return user && user.role === 'temp';
  }

  /**
   * Get data of the currently logged user that was returned by the last
   * call to {@link UserAccount#login} or
   * {@link UserAccount#authorize}. Return null when there
   * is no user logged in or the data of the current user were not fetched
   * yet.
   *
   * @returns {UserAccount} An Account instance.
   */
  public getCachedCurrent(): UserAccount {
    return this.auth.getCurrentUserData();
  }

  /**
   * Get data of the currently logged access tokern that was returned by the last
   * call to {@link UserAccount#login}
   *
   * @returns object An AccessToken instance.
   */
  public getCurrentToken(): AccessToken {
    return this.auth.getToken();
  }

  /**
   * @returns {boolean} True if the current user is authenticated (logged in).
   */
  public isAuthenticated() {
    return !(this.getCurrentId() === '' || this.getCurrentId() == null || this.getCurrentId() == 'null');
  }

  /**
   * Актуальна ли информация о юзере.
   * Возврашает `true` после логина или после отработки {@link UserAccount#authorize}.
   * @return {boolean}
   */
  public isUpToDate(): boolean {
    return this.upToDate;
  }

  /**
   * @returns object Id of the currently logged-in user or null.
   */
  public getCurrentId() {
    return this.auth.getCurrentUserId();
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccount`.
   */
  public getModelName() {
    return 'UserAccount';
  }
}
