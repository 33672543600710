import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {emailTakenValidator, markAsTouched, passwordConfirmValidator} from '../../shared';
import {Observable, of, Subject} from 'rxjs';
import {ServerError, UserAccount, UserSignupInterface as SubmitData} from '../../shared/sdk/models';
import {catchError, filter, switchMap, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {LoggerService, UserAccountApi} from '../../shared/sdk/services/custom';
import {ConfirmEmailService} from '../../confirm-email/confirm-email.service';
import {Router} from '@angular/router';
import {GuestLoginService} from '../guest-login.service';

interface FormData extends SubmitData {
  passwordConfirm?: string;
  nativeLanguage?: string;
}

@Component({
  selector: 'app-guest-login-form',
  templateUrl: './guest-login-form.component.html',
  styleUrls: ['./guest-login-form.component.scss']
})
export class GuestLoginFormComponent implements OnInit, OnDestroy {

  public readonly userId = this.userApi.getCurrentId();

  public signupErrorMessage: string;

  public submitting = false;

  public form: FormGroup = this.fb.group({
    email: this.fb.control(
      '',
      [Validators.required, Validators.email],
      [emailTakenValidator(this.userApi)]
    ),
    name: this.fb.control(
      '',
      [Validators.required, Validators.minLength(2)]
    ),
    password: this.fb.control(
      '',
      [Validators.required, Validators.minLength(6)]
    ),
    passwordConfirm: this.fb.control(
      '',
      [Validators.required]
    ),
    nativeLanguage: this.fb.control(
      'auto',
      [Validators.required]
    ),
  }, {validator: passwordConfirmValidator});
  public controls: {[p: string]: AbstractControl} = this.form.controls;

  public get passwordConfirmError(): boolean {
    const {errors} = this.form;
    return errors && errors.passwordConfirm;
  }

  private onDestroySource = new Subject<boolean>();

  private signupFormSubmitSource = new Subject<UserAccount>();
  private signupFormSubmit$ = this.signupFormSubmitSource.asObservable()
    .pipe(
      filter(() => this.form.valid),
      withLatestFrom(this.form.valueChanges, (_, values: FormData) => ({
        name: values.name.trim(),
        email: values.email.trim(),
        password: values.password.trim(),
        lang: values.nativeLanguage,
        temp_user_id: this.userApi.getCurrentId()
      })),
      tap(values => this.logger.info(this, 'Submitting with data', values)),
      switchMap(values => {
        this.submitting = true;
        this.signupErrorMessage = null;
        return this.userApi
          .register(values)
          .pipe(
            catchError(this.errorHandler.bind(this))
          );
      }),
      filter(user => user !== undefined)
    );

  constructor(private logger: LoggerService,
              private fb: FormBuilder,
              private router: Router,
              private guestLoginService: GuestLoginService,
              private confirmEmailService: ConfirmEmailService,
              private userApi: UserAccountApi) {}

  public ngOnInit() {
    this.signupFormSubmit$
      .pipe(
        takeUntil(this.onDestroySource)
      )
      .subscribe(
        (user: UserAccount) => {
          this.logger.info(this, `User ${user.name} (${user.email}) successfully registered`, user);
          this.confirmEmailService.openConfirmEmail();
          this.guestLoginService.hideTemplateUserRegister();
          this.router.navigateByUrl('/statistics', { skipLocationChange: true }).then(() =>
            this.router.navigate(['/']));
        }
      );
  }

  public onSignupFormSubmit(): void {
    markAsTouched(this.form);
    this.signupFormSubmitSource.next();
  }

  private errorHandler(err: ServerError): Observable<void> {
    this.logger.error(this, `Registration`, err);
    this.form.setValue({
      password: '',
      passwordConfirm: ''
    }, { emitEvent: false });
    this.submitting = false;
    this.signupErrorMessage = 'Unknown error occured. Please, try again later.';
    return of(undefined);
  }

  public ngOnDestroy(): void {
    this.onDestroySource.next();
    this.onDestroySource.complete();
  }

}
