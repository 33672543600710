export default [
  { lang: 'auto', langName: 'Not selected' },
  { lang: 'af', langName: 'Afrikaans' },
  { lang: 'sq', langName: 'Albanian' },
  { lang: 'am', langName: 'Amharic' },
  { lang: 'ar', langName: 'Arabic' },
  { lang: 'eu', langName: 'Basque' },
  { lang: 'be', langName: 'Belarusian' },
  { lang: 'bn', langName: 'Bengali' },
  { lang: 'bs', langName: 'Bosnian' },
  { lang: 'bg', langName: 'Bulgarian' },
  { lang: 'ca', langName: 'Catalan' },
  { lang: 'ceb', langName: 'Cebuano' },
  { lang: 'zh-TW', langName: 'Chinese Traditional' },
  { lang: 'zh-CN', langName: 'Chinese Simplified' },
  { lang: 'co', langName: 'Corsican' },
  { lang: 'hr', langName: 'Croatian' },
  { lang: 'cs', langName: 'Czech' },
  { lang: 'da', langName: 'Danish' },
  { lang: 'nl', langName: 'Dutch' },
  { lang: 'en', langName: 'English' },
  { lang: 'eo', langName: 'Esperanto' },
  { lang: 'et', langName: 'Estonian' },
  { lang: 'fi', langName: 'Finnish' },
  { lang: 'fr', langName: 'French' },
  { lang: 'fy', langName: 'Frisian' },
  { lang: 'gl', langName: 'Galician' },
  { lang: 'ka', langName: 'Georgian' },
  { lang: 'de', langName: 'German' },
  { lang: 'el', langName: 'Greek' },
  { lang: 'gu', langName: 'Gujarati' },
  { lang: 'ht', langName: 'Haitian Creole' },
  { lang: 'ha', langName: 'Hausa' },
  { lang: 'haw', langName: 'Hawaiian' },
  { lang: 'iw', langName: 'Hebrew' },
  { lang: 'hi', langName: 'Hindi' },
  { lang: 'hmn', langName: 'Hmong' },
  { lang: 'hu', langName: 'Hungarian' },
  { lang: 'is', langName: 'Icelandic' },
  { lang: 'ig', langName: 'Igbo' },
  { lang: 'id', langName: 'Indonesian' },
  { lang: 'ga', langName: 'Irish' },
  { lang: 'it', langName: 'Italian' },
  { lang: 'ja', langName: 'Japanese' },
  { lang: 'jw', langName: 'Javanese' },
  { lang: 'kn', langName: 'Kannada' },
  { lang: 'kk', langName: 'Kazakh' },
  { lang: 'km', langName: 'Khmer' },
  { lang: 'ko', langName: 'Korean' },
  { lang: 'ku', langName: 'Kurdish Kurmanji' },
  { lang: 'ky', langName: 'Kyrgyz' },
  { lang: 'lo', langName: 'Lao' },
  { lang: 'la', langName: 'Latin' },
  { lang: 'lv', langName: 'Latvian' },
  { lang: 'lt', langName: 'Lithuanian' },
  { lang: 'lb', langName: 'Luxembourgish' },
  { lang: 'mk', langName: 'Macedonian' },
  { lang: 'mg', langName: 'Malagasy' },
  { lang: 'ms', langName: 'Malay' },
  { lang: 'ml', langName: 'Malayalam' },
  { lang: 'mt', langName: 'Maltese' },
  { lang: 'mi', langName: 'Maori' },
  { lang: 'mr', langName: 'Marathi' },
  { lang: 'mn', langName: 'Mongolian' },
  { lang: 'ne', langName: 'Nepali' },
  { lang: 'no', langName: 'Norwegian' },
  { lang: 'ps', langName: 'Pashto' },
  { lang: 'fa', langName: 'Persian' },
  { lang: 'pl', langName: 'Polish' },
  { lang: 'pt', langName: 'Portuguese' },
  { lang: 'pa', langName: 'Punjabi' },
  { lang: 'ro', langName: 'Romanian' },
  { lang: 'ru', langName: 'Russian' },
  { lang: 'sm', langName: 'Samoan' },
  { lang: 'gd', langName: 'Scots Gaelic' },
  { lang: 'sr', langName: 'Serbian' },
  { lang: 'st', langName: 'Sesotho' },
  { lang: 'sn', langName: 'Shona' },
  { lang: 'sd', langName: 'Sindhi' },
  { lang: 'si', langName: 'Sinhala' },
  { lang: 'sk', langName: 'Slovak' },
  { lang: 'sl', langName: 'Slovenian' },
  { lang: 'so', langName: 'Somali' },
  { lang: 'es', langName: 'Spanish' },
  { lang: 'su', langName: 'Sundanese' },
  { lang: 'sw', langName: 'Swahili' },
  { lang: 'sv', langName: 'Swedish' },
  { lang: 'tg', langName: 'Tajik' },
  { lang: 'ta', langName: 'Tamil' },
  { lang: 'te', langName: 'Telugu' },
  { lang: 'th', langName: 'Thai' },
  { lang: 'tr', langName: 'Turkish' },
  { lang: 'uk', langName: 'Ukrainian' },
  { lang: 'ur', langName: 'Urdu' },
  { lang: 'uz', langName: 'Uzbek' },
  { lang: 'vi', langName: 'Vietnamese' },
  { lang: 'cy', langName: 'Welsh' },
  { lang: 'xh', langName: 'Xhosa' },
  { lang: 'yi', langName: 'Yiddish' },
  { lang: 'yo', langName: 'Yoruba' },
  { lang: 'zu', langName: 'Zulu' }
];

export interface NativeLangs {
  lang: string;
  langName: string;
}
