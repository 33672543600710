/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
/**
* @author Jonathan Casarrubias <twitter:@johncasarrubias> <github:@mean-expert-official>
* @module JSONSearchParams
* @license MIT
* @description
* JSON Parser and Wrapper for the Angular2 HttpParams
* This module correctly encodes a json object into a query string and then creates
* an instance of the HttpParams component for later use in HTTP Calls
**/
@Injectable()
export class JSONSearchParams {

    private _usp: HttpParams;

    public setJSON(obj: any) {
        this._usp = new HttpParams({ fromString: this._JSON2URL(obj, false) });
    }

    public getURLSearchParams(): HttpParams {
        return this._usp;
    }

    private _JSON2URL(obj: any, parent: any) {
        var parts: any = [];
        for (var key in obj) {
          parts.push(this._parseParam(key, obj[key], parent));
        }
        return parts.join('&');
    }

    private _parseParam(key: string, value: any, parent: string) {
        let processedKey = parent ? parent + '[' + key + ']' : key;
        if (value && (<string>(typeof value) === 'object' || Array.isArray(value))) {
            return this._JSON2URL(value, processedKey);
        }
        return processedKey + '=' + encodeURIComponent(value);
    }
}
