import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {LoopBackConfig} from '../../lb.config';
import {Observable} from 'rxjs';
import {UserExercise, UserExerciseInterface} from '../../models';
import {map} from 'rxjs/operators';

/**
 * Api services for the `UserExercise` model.
 */
@Injectable()
export class UserExerciseApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, searchParams, errorHandler);
  }

  /** получить картинку с сервера */

  /**
   * @returns {Observable<UserExerciseInterface>}
   */
  public getStoryExercises(userStoryId): Observable<UserExercise[]> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'web/exercises/:userStoryId'
    ].join('/'), {userStoryId}).pipe(
      map<UserExerciseInterface[], UserExercise[]>(exercises => exercises.map(exercise => new UserExercise(exercise)))
    );
  }

  /**
   * @returns {Observable<void>}
   */
  public sendExerciseResults(userStoryId: number, exerciseId: number,
                             state: 'not_seen' | 'passed' | 'failed', timezoneOffset: number): Observable<void> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'web/exercises/results/:userStoryId'
    ].join('/'), {userStoryId}, {timezoneOffset}, {
      id: exerciseId,
      state
    });
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UserExercise`.
   */
  public getModelName() {
    return 'UserExercise';
  }
}
