/* tslint:disable */
declare var Object: any;

export type PaypalPaymentState = 'created'|'approved'|'failed'

export interface PaypalPaymentInterface {
  id?: string;
  userAccountId?: string;
  payment?: object;
  createdAt?: Date;
  updatedAt?: Date;
  state?: PaypalPaymentState;
}

export class PaypalPayment implements PaypalPaymentInterface {
  id: string;
  userAccountId: string;
  payment: object;
  createdAt: Date;
  updatedAt: Date;
  state: PaypalPaymentState;

  constructor(data: PaypalPaymentInterface = {}) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `PaypalPayment`.
   */
  public static getModelName() {
    return 'PaypalPayment';
  }

  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of PaypalPayment for dynamic purposes.
   **/
  public static factory(data: PaypalPaymentInterface): PaypalPayment {
    return new PaypalPayment(data);
  }

  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'PaypalPayment',
      plural: 'PaypalPayments',
      properties: {
        id: {
          name: 'id',
          type: 'string'
        },
        userAccountId: {
          name: 'userAccountId',
          type: 'string'
        },
        payment: {
          name: 'payment',
          type: 'object'
        },
        createdAt: {
          name: 'createdAt',
          type: 'date'
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'date'
        },
        state: {
          name: 'state',
          type: 'string'
        }
      }
    };
  }
}
