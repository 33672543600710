<div class="text-center">
    <div class="cover-container d-flex h-100 p-3 mx-auto flex-column text-center">
        <main role="main" class="inner cover">
            <h1 class="cover-heading">404 Page not found</h1>
            <p class="lead">Oops! You're lost. The page you are looking for was not found.
            <p class="lead">
                <button class="btn btn-lg btn-secondary mr-3"
                        (click)="goBack()">Go back</button>
                <a routerLink="/"
                   class="btn btn-lg btn-secondary">Front Page</a>
            </p>
        </main>
    </div>
</div>