import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';


@Injectable()
export class TitleResolver implements Resolve<any> {
  constructor(
    private title: Title
  ) {}

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return of(true).pipe(
      tap(() => route.data.title ? this.title.setTitle(route.data.title) : this.title.setTitle('English Gym'))
    );
  }
}
