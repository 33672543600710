/* tslint:disable */
import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {UserFeedback, UserFeedbackInterface, UserSettings} from '../../models';
import {LoopBackConfig} from '../../lb.config';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';

/**
 * Api services for the `UserFeedback` model.
 */
@Injectable()
export class UserFeedbackApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, searchParams, errorHandler);
  }

  /**
   * @param {UserFeedback} data
   * @returns {Observable<UserFeedback>}
   */
  public anonSupport(data: UserFeedbackInterface): Observable<UserFeedback> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'anon'
    ].join('/'), undefined, undefined, {settings: data})
      .pipe(
        map((data: UserFeedbackInterface) => this.model.factory(data))
      );
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UserFeedback`.
   */
  public getModelName() {
    return 'UserFeedback';
  }
}
