import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {LevelsService} from './levels.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class HasLevelsGuard implements CanActivate {

  constructor(private levels: LevelsService,
              private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.levels.hasLevels$.pipe(
      tap(hasLevels => hasLevels ? void 0 : this.router.navigate(['/learn-page']))
    );
  }
}
