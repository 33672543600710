import {AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {UserAccountApi} from './sdk/services/custom';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/internal/operators';

export const noneCheckedValidator: ValidatorFn = (group: FormGroup): ValidationErrors | null => {
  let noneChecked = true;
  const values: {[name: string]: boolean} = group.value;
  for (const controlName in values) {
    if (group.value[controlName]) {
      noneChecked = false;
      break;
    }
  }
  return noneChecked ? {noneChecked: true} : null;
};

export const passwordConfirmValidator: ValidatorFn = (group: FormGroup): ValidationErrors | null => {
  const password = group.get('password').value;
  const passwordConfirm = group.get('passwordConfirm').value;
  return password !== passwordConfirm ? { passwordConfirm: true } : null;
};

export function emailTakenValidator(userApi: UserAccountApi): AsyncValidatorFn {
  return (ctrl: AbstractControl): Observable<ValidationErrors | null>  =>
    userApi.isEmailTaken(ctrl.value.trim())
      .pipe(
        map(taken => (taken ? { emailTaken: true } : null)),
        catchError(() => of(null))
      );
}
