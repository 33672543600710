/* tslint:disable */
import {PicturePoolItem, PicturePoolItemInterface} from './PicturePoolItem';
import {PlatformInterface} from '../../../learning-process/platform.info';

export interface UserReportInterface {
  screen?: string;
  reasons?: number;
  screenshotFilename?: string;
  clientTime?: number;
  poolItem?: PicturePoolItemInterface | any;
  payload?: any;
  screenshotDataURI?: string;
}

export class UserReport implements UserReportInterface {
  screen: string;
  screenshotFilename: string;
  clientTime: number = Date.now();
  poolItem: PicturePoolItemInterface;
  payload: any;
  screenshotDataURI: string;

  platform: PlatformInterface;

  /**
   * Bitmask: 1 (voice-over) | 2 (picture) | 4 (word) | 8 (other)
   * @type number
   */
  reasons: number;

  constructor(data: UserReportInterface = {}) {
    Object.assign(this, data);
    if (this.poolItem instanceof PicturePoolItem) {
      this.poolItem = this.poolItem.toJSON();
    }
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserReport`.
   */
  public static getModelName() {
    return "UserReport";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserReport for dynamic purposes.
  **/
  public static factory(data: UserReportInterface): UserReport{
    return new UserReport(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserReport',
      plural: 'UserReports',
      properties: {
        'reasons': {
          name: 'reasons',
          type: 'number'
        },
        'platform': {
          name: 'platform',
          type: 'any'
        },
        'screen': {
          name: 'screen',
          type: 'string'
        },
        'screenshotFilename': {
          name: 'screenshotFilename',
          type: 'string'
        },
        'clientTime': {
          name: 'clientTime',
          type: 'number'
        },
        'poolItem': {
          name: 'poolItem',
          type: 'any'
        },
        'payload': {
          name: 'payload',
          type: 'any'
        },
        'screenshotDataURI': {
          name: 'screenshotDataURI',
          type: 'string'
        }
      },
      relations: {
      }
    }
  }
}
