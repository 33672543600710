/* tslint:disable */
import {PicturePoolItem, PicturePoolItemInterface} from './PicturePoolItem';
import {Picture, PictureInterface} from './Picture';
import {Word, WordInterface} from './Word';

declare var Object: any;

export interface PicturePoolInterface {
  items?: PicturePoolItemInterface[];
  nearestRepetition?: Date;
  randomPictures?: {[wordId: string]: PictureInterface[]};
  randomWords?: {[wordId: string]: WordInterface[]};
}

export interface PicturePoolOptions {
  deviceId?: string;
  statsOnly?: boolean;
}

export class PicturePool implements PicturePoolInterface {
  items: PicturePoolItem[];
  nearestRepetition: Date;
  randomPictures: {[wordId: string]: Picture[]};
  randomWords: {[wordId: string]: Word[]};

  constructor(data: PicturePoolInterface = {}) {
    Object.assign(this, data);
    this.items = data.items
      .map(item  => PicturePoolItem.factory(item));
    if (data.randomPictures) {
      for (const wordId in data.randomPictures) {
        this.randomPictures[wordId] = data.randomPictures[wordId].map(pic => Picture.factory(pic))
      }
    }
    if (data.randomWords) {
      for (const wordId in data.randomWords) {
        this.randomWords[wordId] = data.randomWords[wordId].map(word => Word.factory(word))
      }
    }
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `PicturePool`.
   */
  public static getModelName() {
    return "PicturePool";
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of PicturePool for dynamic purposes.
   **/
  public static factory(data: PicturePoolInterface): PicturePool{
    return new PicturePool(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'PicturePool',
      plural: 'PicturePools',
      properties: {
        items: {
          type: String,
          description: "Слово, которое необходимо назвать юзеру"
        },
        isRepetition: {
          type: Boolean,
          description: 'Является ли набор элементов повторениями или новыми словами'
        },
        randomPictures: {
          type: 'Object',
          description: 'Случайные картинки для режима Pick (режим картинок)'
        },
        randomWords: {
          type: 'Object',
          description: 'Случайные слова для режима Pick (режим аудио)'
        },
        nearestRepetition: {
          type: Date,
          description: 'Дата ближайшего повторения'
        }
      },
      relations: {}
    }
  }
}
