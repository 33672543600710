import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {catchError, filter, mapTo, switchMap, takeUntil, tap, withLatestFrom} from 'rxjs/internal/operators';
import {LoggerService, ServerError, UserFeedbackApi, UserFeedbackInterface} from '../shared/sdk';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable, of, Subject} from 'rxjs';
import {PLATFORM, PlatformInterface} from '../learning-process/platform.info';
import {UserAccountApi} from '../shared/sdk/services/custom';

type State = 'loading'|'submitted'|'submition_error';

interface FormData {
  text: string;
  email: string;
}

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit, OnDestroy {
  public state: State;

  public form: FormGroup = this.fb.group({
    text: this.fb.control(
      '',
      [Validators.required]
    )
  });
  public ctrl: {[p: string]: AbstractControl} = this.form.controls;

  private onDestroySource = new Subject<boolean>();

  public formSubmitSource = new Subject<void>();
  private formSubmit$ = this.formSubmitSource.asObservable()
    .pipe(
      filter(() => this.form.valid),
      withLatestFrom<void, FormData>(this.form.valueChanges, (_, data) => data),
      tap(data => this.logger.info(this, `Sending feedback (${data.email || '<null>'}): ${data.text.slice(0, 64)}`)),
      tap(() => this.state = 'loading'),
      switchMap(data => {
        const feedback: UserFeedbackInterface = Object.assign({
          platform: this.platform
        }, data);
        const stream = this.isAuthenticated
          ? this.userFeedbackApi.create<UserFeedbackInterface>(feedback)
          : this.userFeedbackApi.anonSupport(feedback);
        return stream
          .pipe(
            mapTo(true),
            catchError(this.errorHandler.bind(this))
          );
        }
      ),
      filter(sent => !!sent)
    );

  public isAuthenticated = this.userApi.isAuthenticated();

  constructor(@Inject(PLATFORM) private platform: PlatformInterface,
              private userApi: UserAccountApi,
              private logger: LoggerService,
              private fb: FormBuilder,
              private userFeedbackApi: UserFeedbackApi) {
    if (!this.isAuthenticated) {
      this.form.addControl('email', this.fb.control(
        '',
        [Validators.required, Validators.email]
      ));
    }
  }

  ngOnInit() {
    this.formSubmit$
      .pipe(takeUntil(this.onDestroySource))
      .subscribe(
        () => {
          this.state = 'submitted';
          this.logger.info(this, `Feedback was sent`);
        }
      );
  }

  public onFormSubmit(): void {
    this.formSubmitSource.next();
  }

  private errorHandler(err: ServerError): Observable<boolean> {
    this.state = 'submition_error';
    this.logger.error(this, `Feedback`, err);
    return of(false);
  }

  ngOnDestroy() {
    this.onDestroySource.next(true);
    this.onDestroySource.complete();
  }

}
