/**
 * @module SDKModule
 * @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
 * @license MIT 2016 Jonathan Casarrubias
 * @version 2.1.0
 * @description
 * The SDKModule is a generated Software Development Kit automatically built by
 * the LoopBack SDK Builder open source module.
 *
 * The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
 * can import this Software Development Kit as follows:
 *
 *
 * APP Route Module Context
 * ============================================================================
 * import { NgModule }       from '@angular/core';
 * import { BrowserModule }  from '@angular/platform-browser';
 * // App Root
 * import { AppComponent }   from './app.component';
 * // Feature Modules
 * import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
 * // Import Routing
 * import { routing }        from './app.routing';
 * @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
 * export class AppModule { }
 *
 **/
import {ErrorHandler, JSONSearchParams, LoopBackAuth} from './services/core';
import {InternalStorage, SDKStorage} from './storage/storage.swaps';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CookieBrowser} from './storage/cookie.browser';
import {StorageBrowser} from './storage/storage.browser';
import {
  FileContainerApi,
  LoggerService,
  PaypalPaymentApi,
  PictureApi,
  PictureGroupApi,
  PicturePoolApi,
  PicturePoolHistoryApi,
  PicturePoolWordApi,
  SDKModels,
  UserAccountApi,
  UserFeedbackApi,
  UserGroupPurchaseApi,
  UserReportApi, UserStatisticsApi, UserSubscriptionApi,
  WordApi,
  WordPronunciationApi,
  WordUseExampleApi
} from './services/custom';

/**
 * @module SDKBrowserModule
 * @description
 * This module should be imported when building a Web Application in the following scenarios:
 *
 *  1.- Regular web application
 *  2.- Angular universal application (Browser Portion)
 *  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
 **/
@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [
    ErrorHandler
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: StorageBrowser
  }): ModuleWithProviders {
    return {
      ngModule: SDKBrowserModule,
      providers: [
        LoopBackAuth,
        LoggerService,
        JSONSearchParams,
        SDKModels,
        FileContainerApi,
        UserAccountApi,
        WordApi,
        PictureApi,
        WordPronunciationApi,
        WordUseExampleApi,
        PicturePoolApi,
        PicturePoolWordApi,
        PicturePoolHistoryApi,
        PictureGroupApi,
        UserGroupPurchaseApi,
        PaypalPaymentApi,
        UserStatisticsApi,
        UserSubscriptionApi,
        UserReportApi,
        UserFeedbackApi,
        internalStorageProvider,
        {provide: SDKStorage, useClass: StorageBrowser}
      ]
    };
  }
}

export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export {CookieBrowser} from './storage/cookie.browser';
export {StorageBrowser} from './storage/storage.browser';
