/* tslint:disable */
declare var Object: any;

export interface StoryFileContainerInterface {
    id?: number;
}

export class StoryFileContainer implements StoryFileContainerInterface {
    id: number;

    constructor(data: StoryFileContainerInterface = {}) {
        Object.assign(this, data);
    }
    /**
     * The name of the model represented by this $resource,
     * i.e. `StoryFileContainer`.
     */
    public static getModelName() {
        return "StoryFileContainer";
    }
    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of StoryFileContainer for dynamic purposes.
     **/
    public static factory(data: StoryFileContainerInterface): StoryFileContainer{
        return new StoryFileContainer(data);
    }
    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    public static getModelDefinition() {
        return {
            name: 'StoryFileContainer',
            plural: 'StoryFileContainers',
            properties: {
                "id": {
                    name: 'id',
                    type: 'number'
                },
            },
            relations: {
            }
        }
    }
}
