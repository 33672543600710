import {Injectable} from '@angular/core';
import {LoggerService, UserAccountApi} from '../shared/sdk/services/custom';
import {merge, Subject} from 'rxjs';
import {mapTo, switchMap} from 'rxjs/operators';
import {shareEvent, shareState} from '../shared/rx.operators';

@Injectable()
export class ConfirmEmailService {

  private confirmEmailWindow = false;

  get confirmEmailIsOpen() {
    return this.confirmEmailWindow;
  }

  public readonly resendLetter = new Subject<void>();
  public readonly resendLetter$ = this.resendLetter.asObservable().pipe(
    switchMap(() => this.userApi.resendLetter()),
    shareEvent()
  );

  public readonly resendLetterLoading$ = merge(
    this.resendLetter.asObservable().pipe(mapTo(true)),
    this.resendLetter$.pipe(mapTo(false))
  ).pipe(
    shareState(false)
  );

  constructor(
    private logger: LoggerService,
    private userApi: UserAccountApi
  ) {
  }

  public openConfirmEmail(): void {
    this.logger.info(this, 'Confirm Email Window is open');
    if (!this.userApi.getCachedCurrent().emailVerified && !this.userApi.isGuest()) {
      this.confirmEmailWindow = true;
    }
  }

  public closeConfirmEmail(): void {
    this.logger.info(this, 'Confirm Email Window is close');
    this.confirmEmailWindow = false;
  }

}
