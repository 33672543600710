import {NgModule} from '@angular/core';
import {IfGuestDirective, IfProductionDirective} from './directives';
import {MobileAppsBannerComponent} from './components/mobile-apps-banner/mobile-apps-banner.component';
import {MobileSystemDetectService} from './services/mobile-system-detect.service';
import {CommonModule} from '@angular/common';
import {MsToTimePipePipe} from './pipes/milliseconds-to-time.pipe';
import { StoryPlaylistComponent } from './components/story-playlist/story-playlist.component';
import {DescriptionComponent} from './components/description/description.component';
import {AudioStoryPlayerComponent} from './components/audio-story-player/audio-story-player.component';
import {StatsComponent} from './components/stats/stats.component';
import {NgbDropdownModule, NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import {Ng5SliderModule} from 'ng5-slider';
import {ReactiveFormsModule} from '@angular/forms';
import {DetailedCommentComponent} from './components/study-story-detailed/detailed-comment/detailed-comment.component';
import {DetailedComponent} from './components/study-story-detailed/detailed/detailed.component';
import {StudyStoryDetailedComponent} from './components/study-story-detailed/study-story-detailed.component';
import {DetailedStoryComponent} from './components/study-story-detailed/detailed-story/detailed-story.component';
import { PlayerOverlayComponent } from './components/player-overlay/player-overlay.component';
import { PluralPipe } from './pipes/plural.pipe';
import {MsToTimeDirective} from './directives/ms-to-time.directive';
import {NativeLangsControlDirective} from './directives/native-langs-control.directive';
import { SocialLoginLinkDirective } from './directives/social-login-link.directive';
import { ExerciseDropdownMenuComponent } from './components/exercise-dropdown-menu/exercise-dropdown-menu.component';
import {SliderComponent} from './components/slider/slider.component';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    Ng5SliderModule,
    ReactiveFormsModule
  ],
  declarations: [
    IfProductionDirective,
    IfGuestDirective,
    MobileAppsBannerComponent,
    MsToTimePipePipe,
    StoryPlaylistComponent,
    DescriptionComponent,
    AudioStoryPlayerComponent,
    StatsComponent,
    DetailedCommentComponent,
    DetailedComponent,
    StudyStoryDetailedComponent,
    DetailedStoryComponent,
    PlayerOverlayComponent,
    PluralPipe,
    MsToTimeDirective,
    NativeLangsControlDirective,
    SocialLoginLinkDirective,
    ExerciseDropdownMenuComponent,
    SliderComponent
  ],
  exports: [
    IfProductionDirective,
    IfGuestDirective,
    MobileAppsBannerComponent,
    MsToTimePipePipe,
    StoryPlaylistComponent,
    DescriptionComponent,
    AudioStoryPlayerComponent,
    StatsComponent,
    StudyStoryDetailedComponent,
    DetailedCommentComponent,
    DetailedComponent,
    PlayerOverlayComponent,
    PluralPipe,
    MsToTimeDirective,
    NativeLangsControlDirective,
    SocialLoginLinkDirective,
    ExerciseDropdownMenuComponent,
    SliderComponent
  ],
  providers: [
    MobileSystemDetectService
  ]
})
export class SharedModule {
}
