import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {LoopBackConfig} from '../../lb.config';
import {Observable} from 'rxjs';
import {LearnPage, LearnPageInterface} from '../../models';
import {map} from 'rxjs/operators';

/**
 * Api services for the `UserLevel` model.
 */
@Injectable()
export class UserLevelApi extends BaseLoopBackApi {

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(SDKModels) protected models: SDKModels,
        @Inject(LoopBackAuth) protected auth: LoopBackAuth,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
        @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
    ) {
        super(http, models, auth, searchParams, errorHandler);
    }

    /**
     * @returns {Observable<LearnPage>}
     */
    public getLevelsList(): Observable<LearnPage> {
        const url = [
            LoopBackConfig.getPath(),
            LoopBackConfig.getApiVersion(),
            this.model.getModelDefinition().plural,
            'web/levels'
        ].join('/');
        return this.request('GET', url)
          .pipe(
            map<LearnPageInterface, LearnPage>(data => new LearnPage(data))
          );
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `UserLevel`.
     */
    public getModelName() {
        return 'UserLevel';
    }
}
