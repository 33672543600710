import { Component, OnInit } from '@angular/core';
import {GuestLoginService} from './guest-login.service';

@Component({
  selector: 'app-guest-login',
  templateUrl: './guest-login.component.html',
  styleUrls: ['./guest-login.component.scss']
})
export class GuestLoginComponent implements OnInit {

  public registerMode = false;

  public showRegisterMode() {
    this.registerMode = true;
  }

  constructor(
    public guestLoginService: GuestLoginService
  ) { }

  public ngOnInit() {
  }

}
