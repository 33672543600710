/* tslint:disable */
import {Word, WordInterface} from './Word';

declare var Object: any;

export interface WordPronunciationInterface {
  id?: string;
  container?: string;
  wordId?: string;
  file_name?: string;
  word?: WordInterface
}

export class WordPronunciation implements WordPronunciationInterface {
  id: string;
  container: string;
  wordId: string;
  file_name: string;
  word: Word;

  constructor(data: WordPronunciationInterface = {}) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WordPronunciation`.
   */
  public static getModelName() {
    return "WordPronunciation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WordPronunciation for dynamic purposes.
  **/
  public static factory(data: WordPronunciationInterface): WordPronunciation{
    return new WordPronunciation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WordPronunciation',
      plural: 'WordPronunciations',
      properties: {
        "id": {
          "name": "id",
          "type": "string",
          "id": true
        },
        "container": {
          "name": "container",
          "type": "string"
        },
        "file_name": {
          "name": "file_name",
          "type": "string"
        },
        "wordId": {
          "name": "wordId",
          "type": "Word"
        }
      },
      relations: {
      }
    }
  }
}
