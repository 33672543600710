/* tslint:disable */
import { Injectable } from '@angular/core';
import { LoopBackConfig } from '../../lb.config';
/**
* @author Jonathan Casarrubias <twitter:@johncasarrubias> <github:@johncasarrubias>
* @module LoggerService
* @license MIT
* @description
* Console Log wrapper that can be disabled in production mode
**/
@Injectable()
export class LoggerService {

  static applyPrefixStyle(args: any[], style: string): any[] {
    if (args.length > 0) {
      let componentName = args[0];
      if (typeof componentName === 'object') {
        componentName = componentName.constructor.name;
      }
      args[0] = `%c ${componentName} `;
      args.splice(1, 0, style);
    }
    return args;
  }

  log(...args: any[]) {
    if (LoopBackConfig.debuggable())
    console.log.apply(console, args);
  }

  info(...args: any[]) {
    if (LoopBackConfig.debuggable()) {
      console.info.apply(
        console,
        LoggerService.applyPrefixStyle(args, 'background: #008dc5; color: #fff')
      );
    }
  }

  error(...args: any[]) {
    if (LoopBackConfig.debuggable()) {
      console.error.apply(
        console,
        LoggerService.applyPrefixStyle(args, 'background: #ce3c3c; color: #fff')
      );
    }
  }

  count(arg: string) {
    if (LoopBackConfig.debuggable())
    console.count(arg);
  }

  group(arg: string) {
    if (LoopBackConfig.debuggable())
    console.count(arg);
  }

  groupEnd() {
    if (LoopBackConfig.debuggable())
    console.groupEnd();
  }

  profile(arg: string) {
    if (LoopBackConfig.debuggable())
    console.count(arg);
  }

  profileEnd() {
    if (LoopBackConfig.debuggable())
    console.profileEnd();
  }

  time(arg: string) {
    if (LoopBackConfig.debuggable())
    console.time(arg);
  }

  timeEnd(arg: string) {
    if (LoopBackConfig.debuggable())
    console.timeEnd(arg);
  }
}
