/* tslint:disable */
import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {LoopBackConfig} from '../../lb.config';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {Observable} from 'rxjs';
import {FileContainer} from '../../models';

/**
 * Api services for the `FileContainer` model.
 */
@Injectable()
export class FileContainerApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, searchParams, errorHandler);
  }


  /** получить файл с сервера */
  public getImage(container: string, fileName: string): Observable<Blob> {
    let url = this.getFileUrl(container, fileName);
    return this.http.get(url, { responseType: "blob" });
  }

  /**
   * Формирование URL к файлу.
   *
   * @param {string} container
   * @param {string} file
   * @returns {string} URl
   */
  public getFileUrl(container: string, file: string): string {
    return FileContainerApi.getContainerUrl(container) + `/${file}`;
  }

  /**
   * Формирование URL к контейнеру.
   *
   * @param {string} container
   * @returns {string} URl
   */
  static getContainerUrl(container: string): string {
    return `${LoopBackConfig.getPath()}/storage/${container}`;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `FileContainer`.
   */
  public getModelName() {
    return 'FileContainer';
  }
}
