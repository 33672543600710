/* tslint:disable */

declare var Object: any;

export namespace nsUserStatistics {
  export type StatsType = 'today' | 'total';

  export interface Weekday {
    date: string;
    active: boolean;
  }

  export interface Main {
    date?: Date;
    wordsTotal?: number;
    timeTotal?: number;
    timeThinking?: number;
    correctAnswers?: number;
    wrongAnswers?: number;
    wordsLearned?: number;
    wordsRemoved?: number;
    wordsFavorited?: number;
    wordsProgress?: number;
    maxRepetitions?: number;
    repetitionsProgress?: number;
  }

  export interface Study {
    lastWeekActivity: Weekday[]
  }
}

export interface UserStatisticsInterface {
  type: nsUserStatistics.StatsType;
  statistics: nsUserStatistics.Main;
}

export class UserStatistics implements UserStatisticsInterface {
  type: nsUserStatistics.StatsType;
  statistics: nsUserStatistics.Main;

  constructor(data: UserStatisticsInterface) {
    Object.assign(this, data, {
      date: new Date(data.statistics.date)
    });
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UserStatistics`.
   */
  public static getModelName() {
    return "UserStatistics";
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of UserStatistics for dynamic purposes.
   **/
  public static factory(data: UserStatisticsInterface): UserStatistics{
    return new UserStatistics(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'UserStatistics',
      plural: 'UserStatistics',
      properties: {},
      relations: {}
    }
  }
}