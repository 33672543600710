<div class="bg-light p-4 px-md-5 py-md-4 rounded-lg">
  <form id="formSignup"
        [formGroup]="form"
        (ngSubmit)="onSignupFormSubmit()">

    <h3 class="text-center mb-4 mt-2 font-weight-light text-primary">
      Registration
    </h3>

    <div class="form-group">
      <label for="signupFormEmail" class="sr-only">Email address</label>
      <input type="email"
             id="signupFormEmail"
             class="form-control py-md-4"
             placeholder="Email"
             formControlName="email"
             required=""
             autofocus=""
             [ngClass]="{
                'is-valid': controls['email'].valid,
                'is-invalid': controls['email'].invalid && controls['email'].touched
            }">
      <div class="invalid-feedback"
           *ngIf="controls['email'].errors as errors">
        <ng-container *ngIf="errors['email']">
          Please, enter a valid email address
        </ng-container>
        <ng-container *ngIf="errors['emailTaken']">
          This email address is already registered
        </ng-container>
      </div>
    </div>

    <div class="form-group">
      <label for="signupFormName" class="sr-only">Name</label>
      <input type="text"
             id="signupFormName"
             class="form-control py-md-4"
             placeholder="Name"
             formControlName="name"
             required=""
             [ngClass]="{
                    'is-valid': controls['name'].valid,
                    'is-invalid': controls['name'].invalid && controls['name'].touched
               }">
      <div class="invalid-feedback"
           *ngIf="controls['name'].errors as errors">
        <ng-container *ngIf="errors['minlength']">
          Name should be at least {{ errors['minlength']['requiredLength'] }} characters
        </ng-container>
      </div>
    </div>

    <div class="form-group">
      <label for="signupFormPassword" class="sr-only">Password</label>
      <input type="password"
             id="signupFormPassword"
             class="form-control py-md-4"
             placeholder="Password"
             formControlName="password"
             required=""
             autocomplete="new-password"
             [ngClass]="{
                    'is-valid': controls['password'].valid,
                    'is-invalid': controls['password'].invalid && controls['password'].touched
               }">
      <div class="invalid-feedback"
           *ngIf="controls['password'].errors as errors">
        <ng-container *ngIf="errors['minlength']">
          Password should be at least {{ errors['minlength']['requiredLength'] }} characters
        </ng-container>
      </div>
    </div>

    <div class="form-group">
      <label for="signupFormPasswordConfirm" class="sr-only">Confirm password</label>
      <input type="password"
             id="signupFormPasswordConfirm"
             class="form-control py-md-4"
             formControlName="passwordConfirm"
             required=""
             placeholder="Confirm password"
             autocomplete="new-password"
             [ngClass]="{
                    'is-valid': !passwordConfirmError && controls['passwordConfirm'].valid,
                    'is-invalid': (passwordConfirmError || controls['passwordConfirm'].invalid)
                        && controls['passwordConfirm'].touched
               }">
      <div class="invalid-feedback"
           *ngIf="passwordConfirmError">
        Passwords do not match
      </div>
    </div>

    <div class="form-group mt-3">
      <label for="signupFormNativeLanguage" class="w-100 text-center text-primary fs-label">Native language</label>
      <select id="signupFormNativeLanguage"
              class="form-control form-control-primary"
              formControlName="nativeLanguage"
              appNativeLangsControl
              required="">
      </select>
      <div class="invalid-feedback"
           *ngIf="controls['name'].errors as errors">
        <ng-container *ngIf="errors['required']">
          Native language must be selected
        </ng-container>
      </div>
    </div>

    <div *ngIf="signupErrorMessage" class="alert alert-danger mt-1">
      {{signupErrorMessage}}
    </div>

    <!--<div class="form-check">-->
      <!--<input id="signupFormEmailOffers" type="checkbox" class="form-check-input">-->
      <!--<label for="signupFormEmailOffers" class="form-check-label small">I would like to receive special offers by email</label>-->
    <!--</div>-->

    <button class="btn btn-info btn-block mt-4 btn-sm"
            type="submit"
            [disabled]="submitting">
      Register
    </button>

    <h6 class="my-3 font-weight-light text-muted text-center small">
      or via
    </h6>
    <div class="row">
      <div class="col-6">
        <a class="btn btn-facebook btn-block rounded-sm btn-sm"
           [appSocialLoginLink]="{provider: 'facebook', tempUserId: userId}">
          Facebook
        </a>
      </div>
      <div class="col-6">
        <a class="btn btn-google btn-block rounded-sm btn-sm"
           [appSocialLoginLink]="{provider: 'google', tempUserId: userId}">
          Google
        </a>
      </div>
    </div>

    <p class="text-center mt-4 agree-text">
      By registering, you agree to our Terms of Use. Learn how we collect, use and share your data in our Privacy Policy and how we use cookies and similar technology in our Cookie Policy.
    </p>
  </form>
</div>
