/* tslint:disable */
declare var Object: any;

export interface UserSignupInterface {
  email: string;
  name: string;
  password: string;
  level?: number;
  lang?: string;
  temp_user_id?: string;
}

export interface UserSettings {
  lang: string;
  auditionPickEnabled: boolean;
  picturePickEnabled: boolean;
  backgroundMusicVolume: number;
}

export interface UserAccountInterface {
  id?: string;
  email?: string;
  password?: string;
  name?: string;
  roleId?: string;
  role?: string;
  device_code?: string;
  created_at?: Date;
  archive?: boolean;
  hasSubscription?: boolean;
  last_activity?: Date;
  pool_version?: string;
  settings?: UserSettings;
  _token?: string;
}

export class UserAccount implements UserAccountInterface {
  id: string;
  email: string;
  password: string;
  name: string;
  roleId: string;
  role: string;
  device_code: string;
  created_at: Date;
  archive: boolean;
  emailVerified?: boolean;
  hasSubscription: boolean;
  last_activity: Date;
  pool_version: string;
  settings: UserSettings;
  _token: string;

  constructor(data: UserAccountInterface = {}) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccount`.
   */
  public static getModelName() {
    return "UserAccount";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAccount for dynamic purposes.
  **/
  public static factory(data: UserAccountInterface): UserAccount {
    return new UserAccount(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAccount',
      plural: 'UserAccounts',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "roleId": {
          name: 'roleId',
          type: 'string'
        },
        "role": {
          name: 'role',
          type: 'string'
        },
        "device_code": {
          name: 'device_code',
          type: 'string'
        },
        "created_at": {
          name: 'created_at',
          type: 'Date'
        },
        "archive": {
          name: 'archive',
          type: 'boolean'
        },
        "pool_version": {
          name: 'pool_version',
          type: 'string'
        }
      },
      relations: {
      }
    }
  }
}
