import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild
} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {UserAccountApi, LoggerService} from './shared/sdk/services';
import {RouteOptions} from './route-options';
import {catchError, mapTo, tap} from 'rxjs/internal/operators';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private accountApi: UserAccountApi,
              private router: Router,
              private logger: LoggerService) {
  }

  public static getRouteData(currentRoute: ActivatedRouteSnapshot): RouteOptions {
    let data = {};
    for (let parentRoute = currentRoute; parentRoute; parentRoute = parentRoute.parent) {
      data = Object.assign({}, parentRoute.data, data);
    }
    return data;
  }

  public canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const authenticated = this.accountApi.isAuthenticated();
    const upToDate = this.accountApi.isUpToDate();

    const routeOptions = AuthGuard.getRouteData(route);

    const isPageWithLandingVersion = window.location.href.indexOf('landing=') > -1;

    if (isPageWithLandingVersion) { window.location.reload(); }

    // this.logger.info('AuthGuard',
    //   `fragment '${route.routeConfig.path}'`,
    //   'Options:', routeOptions,
    //   'Authenticated:', authenticated);

    if (authenticated && routeOptions.forAnonymous) {
      // this.logger.info(`AuthGuard`,
      //   'Your are authenticated, but this page for anonymous users only. Redirecting to frontpage...');
      this.router.navigate(['/']);
      return false;
    }
    if (!authenticated && !routeOptions.forAnonymous) {
      // this.logger.info('AuthGuard',
      //   'Your are not authenticated for this page, redirecting to login page...');
      this.router.navigateByUrl('/user/signin');
      return false;
    }
    if (!routeOptions.forAnonymous && !upToDate) {
      // this.logger.info('AuthGuard', 'Updating user info...');
      return this.accountApi.authorize()
        .pipe(
          mapTo(true),
          catchError(err => {
            this.logger.error('AuthGuard', 'Failed to get current user', err);
            return this.accountApi.logout().pipe(mapTo(false));
          })
        );
    }
    return true;
  }

  public canActivateChild(route: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
