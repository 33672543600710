/* tslint:disable */
import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {Picture, PicturePool, PicturePoolInterface, Word} from '../../models';
import {LoopBackConfig} from '../../lb.config';
import {Observable} from 'rxjs';
import {PicturePoolOptions, PicturePoolHistory} from '../../models';
import {map} from 'rxjs/internal/operators';

/**
 * Api services for the `PicturePool` model.
 * @todo Генерировать и хранить уникальный deviceId и отдавать его в options
 */
@Injectable()
export class PicturePoolApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, searchParams, errorHandler);
  }

  /**
   * @method userPool
   * @license MIT
   * @param {PicturePoolOptions} options
   * @return {Observable<PicturePool>}
   */
  public userPool(options: PicturePoolOptions): Observable<PicturePool> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'userPool'
    ].join('/'), undefined, {opts: JSON.stringify(options)}, undefined)
      .pipe(
        map((data: PicturePoolInterface) => this.model.factory(data))
      );
  }

  /**
   * @method webSession
   * @license MIT
   * @return {Observable<PicturePool>}
   */
  public webSession(): Observable<PicturePool> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'webSession'
    ].join('/'))
      .pipe(
        map((data: PicturePoolInterface) => this.model.factory(data))
      );
  }

  /**
   * @method handleStats
   * @license MIT
   * @param {PicturePoolHistory[]} data
   * @param {PicturePoolOptions} options
   * @return {Observable<any>}
   */
  public handleStats(data: PicturePoolHistory[],
                     options: PicturePoolOptions): Observable<any> {
    const timezoneOffset = (new Date).getTimezoneOffset();
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'stats'
    ].join('/'), undefined, {opts: options, timezoneOffset}, {data});
  }

  /**
   * Запрос для режима обучения Pick, в котором юзер выбирает картинку для произнесенного системой слова.
   *
   * Выдает `count` случайных картинок, исключая те, которые содержат
   * `wordIdExclude` - ID слова, которое необходимо распознать.
   *
   * @method getRandomItems
   * @license MIT
   * @param {number} count
   * @param {Word} wordToExclude
   * @return {Observable<Picture[]>}
   */
  public getRandomItems(count: number,
                        wordToExclude: Word): Observable<Picture[]> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'random/:count/exclude/:exclude'
    ].join('/'), {count, exclude: wordToExclude.id})
      .pipe(
        map((data: Picture[]) =>
          data.map((item: Picture) => Picture.factory(item))
        )
      );
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `PicturePool`.
   */
  public getModelName() {
    return 'PicturePool';
  }
}
