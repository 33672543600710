import {Injectable} from '@angular/core';
import {merge, Observable, ReplaySubject, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserStory} from '../../sdk/models';
import {shareState} from '../../rx.operators';

@Injectable()
export class StudyStoryDetailedService {

  /** текущая стори */
  public readonly story = new ReplaySubject<UserStory>(1);
  public readonly story$: Observable<UserStory> = this.story.asObservable();

  /** текст описания стори */
  public readonly descriptionText$: Observable<string> = this.story$.pipe(
    map(story => story.content.text),
    shareState('')
  );

  public readonly closeDetail = new Subject<void>();
  public readonly closeDetail$ = this.closeDetail.asObservable();

  public readonly returnToStudy = new Subject<void>();
  public readonly returnToStudy$ = this.returnToStudy.asObservable();

  /** доп подписки */
  public readonly any$ = merge(
    this.descriptionText$,
    this.closeDetail$
  );

  constructor(
  ) {}
}
