/* tslint:disable */
import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {UserSubscription, UserSubscriptionInterface} from '../../models';
import {LoopBackConfig} from '../../lb.config';
import {Observable} from 'rxjs';
import {map, pluck} from 'rxjs/internal/operators';

/**
 * Api services for the `UserSubscription` model.
 */
@Injectable()
export class UserSubscriptionApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, searchParams, errorHandler);
  }

  /**
   * @method getUserSubscription
   * @license MIT
   * @return {Observable<UserSubscription>}
   */
  public getUserSubscription(): Observable<UserSubscription> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural
    ].join('/'))
      .pipe(
        map((data: UserSubscriptionInterface) => this.model.factory(data))
      );
  }

  /**
   * @method createAgreement
   * @license MIT
   * @param {string} sku
   * @return {Observable<UserSubscription>}
   */
  public createAgreement(sku: string): Observable<string> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'paypal-agreement/:sku'
    ].join('/'), {sku})
      .pipe(
        pluck('redirect')
      );
  }

  /**
   * @method createAgreement
   * @license MIT
   * @return {Observable<void>}
   */
  public cancelAgreement(): Observable<void> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'paypal-cancel-agreement'
    ].join('/'));
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UserSubscription`.
   */
  public getModelName() {
    return 'UserSubscription';
  }
}
