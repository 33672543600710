<main class="container" [ngSwitch]="state">

    <form id="formSupport"
          class="mx-auto"
          [formGroup]="form"
          (ngSubmit)="onFormSubmit()"
          *ngSwitchDefault>

        <h2 class="text-center border-bottom py-3 mb-4 font-weight-light">
            Contact Us
        </h2>

        <div class="form-group"
             *ngIf="!isAuthenticated">
            <input class="form-control"
                   placeholder="Email"
                   formControlName="email"
                   required=""
                   autofocus=""
                   type="email"
                   [ngClass]="{
                     'is-invalid': ctrl.text.invalid && ctrl.text.touched
                   }">
        </div>

        <div class="form-group">
            <textarea class="form-control"
                      placeholder="Tell us what you think"
                      formControlName="text"
                      required=""
                      autofocus=""
                      [ngClass]="{
                        'is-invalid': ctrl.text.invalid && ctrl.text.touched
                      }">
            </textarea>
        </div>

        <div class="border-top pt-4 mt-4 text-center">
            <button class="btn btn-success"
                    type="submit"
                    [disabled]="state === 'loading' || form.invalid">
                Send
            </button>
        </div>
    </form>

    <div class="my-5 py-5 position-relative"
         *ngSwitchCase="'loading'">
        <div class="spinner"></div>
    </div>

    <ng-container *ngSwitchCase="'submitted'">
        <div class="alert alert-success mt-3"
             role="alert">
            <i class="oi oi-check"></i>
            Thanks for your feedback!
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'submition_error'">
        <div class="alert alert-danger mt-3"
             role="alert">
            <i class="oi oi-x"></i>
            Unable to send feedback due to server error. Please, try again later.
        </div>
    </ng-container>

</main>