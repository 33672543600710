/* tslint:disable */
import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {UserReport} from '../../models';
import {LoopBackConfig} from '../../lb.config';

/**
 * Api services for the `UserReport` model.
 */
@Injectable()
export class UserReportApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, searchParams, errorHandler);
  }

  /**
   * @returns {string}
   */
  public getUploadUrl(): string {
    const path = [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().plural,
      'upload-screenshot'
    ].join('/');
    return `${path}?access_token=${this.auth.getAccessTokenId()}`;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UserReport`.
   */
  public getModelName() {
    return 'UserReport';
  }
}
