import {Directive, ElementRef, Input} from '@angular/core';
import {environment as env} from '../../../environments/environment';

declare var navigator: Navigator & {
  userLanguage?: string;
};
const language = (navigator.userLanguage || navigator.language).split('-')[0];

export interface SocialLoginLinkData {
  provider: string;
  tempUserId?: string;
}

@Directive({
  selector: '[appSocialLoginLink]'
})
export class SocialLoginLinkDirective {
  private readonly a: HTMLAnchorElement = this.el.nativeElement;

  @Input()
  public set appSocialLoginLink(data: SocialLoginLinkData) {
    const urlInst = new URL(env.authURL[data.provider], env.baseURL);
    urlInst.searchParams.append('lang', language);
    if (data.tempUserId) {
      urlInst.searchParams.append('temp_user_id', data.tempUserId);
    }
    this.a.href = urlInst.toString();
  }

  constructor(private el: ElementRef) {
  }
}
