/* tslint:disable */
import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi, LoopBackAuth, JSONSearchParams, ErrorHandler} from '../core';
import {WordPronunciation} from '../../models';


/**
 * Api services for the `WordPronunciation` model.
 *
 * **Details**
 *
 * Список слов или набора слов
 */
@Injectable()
export class WordPronunciationApi extends BaseLoopBackApi {

  constructor(@Inject(HttpClient) protected http: HttpClient,
              @Inject(SDKModels) protected models: SDKModels,
              @Inject(LoopBackAuth) protected auth: LoopBackAuth,
              @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
              @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler) {
    super(http, models, auth, searchParams, errorHandler);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `WordPronunciation`.
   */
  public getModelName() {
    return 'WordPronunciation';
  }
}
