import { Injectable } from '@angular/core';

declare var window: Window & {
  opera: any;
  MSStream: any;
};

export enum PlatformEnum {
  android = 'android',
  ios = 'ios',
  other = 'other'
}

@Injectable()
export class MobileSystemDetectService {

  public platform: PlatformEnum;
  public isOther: boolean;
  public isAndroid: boolean;
  public isIos: boolean;

  constructor() {
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      this.platform = PlatformEnum.android;
    }
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.platform = PlatformEnum.ios;
    }
    else {
      this.platform = PlatformEnum.other;
    }
    this.isOther = this.platform === PlatformEnum.other;
    this.isAndroid = this.platform === PlatformEnum.android;
    this.isIos = this.platform === PlatformEnum.ios;
  }
}
