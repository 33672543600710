/* tslint:disable */
import {Word, WordInterface} from './Word';
import {PictureGroup, PictureGroupInterface} from './PictureGroup';
import {UserAccount, UserAccountInterface} from './UserAccount';

declare var Object: any;

export interface PictureSizesInterface {
  width: number;
  height: number;
}

export interface PictureRectInterface extends PictureSizesInterface{
  x: number;
  y: number;
}

export interface PictureInterface {
  id?: string;
  file_name?: string;
  container?: string;
  rect?: PictureRectInterface;
  sizes?: PictureSizesInterface;
  changed_by?: string;
  changed_at?: Date;
  created_by?: string;
  created_at?: Date;
  pictureGroupId?: string;
  is_complex?: boolean;
  pictureGroup?: PictureGroupInterface;
  changedBy?: UserAccountInterface;
  words?: WordInterface[];
  defaultWord?: WordInterface;
}

export class Picture implements PictureInterface {
  id: string;
  file_name: string;
  container: string;
  rect: PictureRectInterface;
  sizes: PictureSizesInterface;
  changed_by: string;
  changed_at: Date;
  created_by: string;
  created_at: Date;
  pictureGroupId: string;
  is_complex: boolean;
  pictureGroup: PictureGroup;
  changedBy: UserAccount;
  words: Word[];

  constructor(data: PictureInterface = {}) {
    Object.assign(this, data, {
      pictureGroup: data.pictureGroup && PictureGroup.factory(data.pictureGroup),
      changedBy: data.changedBy && UserAccount.factory(data.changedBy),
      words: data.words && data.words.map((word: WordInterface) => Word.factory(word))
    });
  }

  public toJSON(): PictureInterface {
    const copy = Object.assign({}, this);
    delete copy.pictureGroup;
    delete copy.changedBy;
    return copy;
  }

  public isRectEmpty(): boolean {
    return !this.rect.width && !this.rect.height;
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Picture`.
   */
  public static getModelName() {
    return "Picture";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Picture for dynamic purposes.
  **/
  public static factory(data: PictureInterface): Picture{
    return new Picture(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Picture',
      plural: 'Pictures',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "file_name": {
          name: 'file_name',
          type: 'string'
        },
        "container": {
          name: 'container',
          type: 'string'
        },
        "words": {
          name: 'words',
          type: 'Array<any>',
          default: <any>[]
        },
        "rect": {
          name: 'rect',
          type: 'any',
          default: <any>null
        },
        "changed_by": {
          name: 'changed_by',
          type: 'string'
        },
        "changed_at": {
          name: 'changed_at',
          type: 'Date'
        },
        "created_by": {
          name: 'created_by',
          type: 'string'
        },
        "created_at": {
          name: 'created_at',
          type: 'Date'
        }
      },
      relations: {
      }
    }
  }
}
