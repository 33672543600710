/* tslint:disable */
import {PictureGroup, PictureGroupInterface} from './PictureGroup';
import {AppError} from '../../app-error.class';

declare var Object: any;

export interface UserPictureGroupInterface {
  purchased?: boolean;
  selected?: boolean;
  group?: PictureGroupInterface;
  wordsTotal?: number;
  wordsLearned?: number;
}

export class UserPictureGroup implements UserPictureGroupInterface {
  public static readonly PURCHASE_NOT_AVAILABLE = Symbol('PURCHASE_NOT_AVAILABLE');

  public purchased: boolean;
  public selected: boolean;
  public group: PictureGroup;
  public wordsTotal: number;
  public wordsLearned: number;
  public purchasable: boolean;

  private _inCart = false;
  get inCart(): boolean {
    return this._inCart;
  }
  set inCart(value: boolean) {
    if (!this.purchasable && value) {
      throw new AppError('This activity is unavailable for purchase', UserPictureGroup.PURCHASE_NOT_AVAILABLE)
    }
    this._inCart = value;
  }

  constructor(data: UserPictureGroupInterface = {}) {
    Object.assign(this, data, {
      group: data.group && PictureGroup.factory(data.group)
    });
    this.purchasable = !this.purchased && this.group.price > 0;
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserPictureGroup`.
   */
  public static getModelName() {
    return "UserPictureGroup";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserPictureGroup for dynamic purposes.
  **/
  public static factory(data: UserPictureGroupInterface): UserPictureGroup {
    return new UserPictureGroup(data);
  }
}
